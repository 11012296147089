<form [formGroup]="form" (ngSubmit)="onSubmit.emit()">
	<div class="grid grid-cols-2 pb-10 md:pr-12" *ngIf="isPersonalSetting">
		<div class="md:col-span-1 col-span-2 flex flex-col">
			<label for="old_password" class="text-green-dark-900 text-xs pl-2 block mb-1">{{ "PERSONAL_SETTINGS.SECURITY.current_password" | translate }} *</label>
			<div class="flex flex-col relative">
				<div class="cursor-pointer absolute right-0 bottom-0 flex items-center justify-center z-10 min-h-[52px] w-12" (click)="showOldPassword($event)">
					<i class="icon-visibility text-3xl sm:text-2xl block text-green-light-900" *ngIf="hideOldPassword"></i>
					<i class="icon-visibility_off text-3xl sm:text-2xl block text-green-light-700" *ngIf="!hideOldPassword"></i>
				</div>
				<input [type]="hideOldPassword?'password':'text'" class="bg-grey-light min-h-[52px] pl-2 pr-12 border-b border-green-dark-900 focus:outline-none focus:border-green-light-600 relative z-0" id="old_password" formControlName="old_password" autocomplete="off">
			</div>
			<div class="md:flex md:gap-12" *ngIf="updateMessage && updateError" class="text-red-700">
				{{updateMessage}}
			</div>
		</div>
	</div>
	<div class="flex flex-col text-center px-2">
		<span class="text-green-dark-900 text-3xl py-7" *ngIf="!hideTitleSection">{{ (isMobileView ? "SIGN_UP.secure_account_title" : "SIGN_UP.create_account") | translate }}</span>
		<span class="text-lg md:text-left" [innerHtml]="'SIGN_UP.secure_account_subtitle' | translate"></span>
	</div>
	<div class="grid grid-cols-2 py-3">
		<div class="col-span-1 flex items-center">
			<div class="rounded w-5 h-5 m-2 bg-white relative" [ngClass]="getPasswordRequirementBoxStyle('minlength')">
				<div *ngIf="isPasswordRequirementControlError('minlength')" class="border-b-2 translate-y-[0.45rem] border-red px-2 rotate-45"></div>
				<div *ngIf="isPasswordRequirementControlError('minlength')" class="border-b-2 translate-y-[0.3rem] border-red px-2 -rotate-45"></div>
				<i *ngIf="isPasswordRequirementControlFulfilled('minlength')" class="icon-checked absolute left-0 text-green-dark-900"></i>
			</div>
			<span class="text-sm font-medium mr-3" [ngClass]="getPasswordRequirementLabelStyle('minlength')">{{ "SIGN_UP.password_requirement.min_length" | translate }}</span>
		</div>
		<div class="col-span-1 flex items-center">
			<div class="rounded w-5 h-5 m-2 bg-white relative" [ngClass]="getPasswordRequirementBoxStyle('lowerCaseMissing')">
				<div *ngIf="isPasswordRequirementControlError('lowerCaseMissing')" class="border-b-2 translate-y-[0.45rem] border-red px-2 rotate-45"></div>
				<div *ngIf="isPasswordRequirementControlError('lowerCaseMissing')" class="border-b-2 translate-y-[0.3rem] border-red px-2 -rotate-45"></div>
				<i *ngIf="isPasswordRequirementControlFulfilled('lowerCaseMissing')" class="icon-checked absolute left-0 text-green-dark-900"></i>
			</div>
			<span class="text-sm font-medium mr-3" [ngClass]="getPasswordRequirementLabelStyle('lowerCaseMissing')">{{ "SIGN_UP.password_requirement.lower_letter" | translate }}</span>
		</div>
		<div class="col-span-1 flex items-center">
			<div class="rounded w-5 h-5 m-2 bg-white relative" [ngClass]="getPasswordRequirementBoxStyle('upperCaseMissing')">
				<div *ngIf="isPasswordRequirementControlError('upperCaseMissing')" class="border-b-2 translate-y-[0.45rem] border-red px-2 rotate-45"></div>
				<div *ngIf="isPasswordRequirementControlError('upperCaseMissing')" class="border-b-2 translate-y-[0.3rem] border-red px-2 -rotate-45"></div>
				<i *ngIf="isPasswordRequirementControlFulfilled('upperCaseMissing')" class="icon-checked absolute left-0 text-green-dark-900"></i>
			</div>
			<span class="text-sm font-medium mr-3" [ngClass]="getPasswordRequirementLabelStyle('upperCaseMissing')">{{
				"SIGN_UP.password_requirement.uppercase_letter" | translate
				}}</span>
		</div>
		<div class="col-span-1 flex items-center">
			<div class="rounded w-5 h-5 m-2 bg-white relative" [ngClass]="getPasswordRequirementBoxStyle('numberMissing')">
				<div *ngIf="isPasswordRequirementControlError('numberMissing')" class="border-b-2 translate-y-[0.45rem] border-red px-2 rotate-45"></div>
				<div *ngIf="isPasswordRequirementControlError('numberMissing')" class="border-b-2 translate-y-[0.3rem] border-red px-2 -rotate-45"></div>
				<i *ngIf="isPasswordRequirementControlFulfilled('numberMissing')" class="icon-checked absolute left-0 text-green-dark-900"></i>
			</div>
			<span class="text-sm font-medium mr-3" [ngClass]="getPasswordRequirementLabelStyle('numberMissing')">{{
				"SIGN_UP.password_requirement.number" | translate
				}}</span>
		</div>
		<div class="col-span-1 flex items-center">
			<div class="rounded w-5 h-5 m-2 bg-white relative" [ngClass]="getPasswordRequirementBoxStyle('specialCharacterMissing')">
				<div *ngIf="isPasswordRequirementControlError('specialCharacterMissing')" class="border-b-2 translate-y-[0.45rem] border-red px-2 rotate-45"></div>
				<div *ngIf="isPasswordRequirementControlError('specialCharacterMissing')" class="border-b-2 translate-y-[0.3rem] border-red px-2 -rotate-45"></div>
				<i *ngIf="isPasswordRequirementControlFulfilled('specialCharacterMissing')" class="icon-checked absolute left-0 text-green-dark-900"></i>
			</div>
			<span class="text-sm font-medium mr-3" [ngClass]="getPasswordRequirementLabelStyle('specialCharacterMissing')">{{
				"SIGN_UP.password_requirement.special_character" | translate
				}}</span>
		</div>
	</div>
	<div class="md:flex md:gap-12">
		<div class="md:flex-1">
			<label for="password" class="text-green-dark-900 text-xs pl-2 block mb-1">{{ isPersonalSetting ? ("SIGN_UP.new_password" | translate) : ("SIGN_UP.password" | translate) }}*</label>
			<div class="flex items-center bg-grey-light min-h-[52px] pl-2 border-b border-green-dark-900 pr-2 focus-within:border-green-light-600 transition-colors" [ngClass]="{'border-red-700 pr-2 focus-within:border-red-500':isFieldInvalid(form, 'password')}">
				<input [type]="hidePassword?'password':'text'" class="outline-none bg-transparent flex-1" id="password" formControlName="password" placeholder="" autocomplete="off">
				<button type="button" class="flex items-center justify-center cursor-pointer" (click)="showPassword($event)">
					<i class="icon-visibility text-3xl text-green-light-700" *ngIf="hidePassword"></i>
					<i class="icon-visibility_off text-3xl text-green-light-700" *ngIf="!hidePassword"></i>
				</button>
			</div>
			<span class="text-red-700 text-xs fon-semibold" *ngIf="isFieldInvalid(form, 'password')">{{'ERRORS.password_specific_requirement' | translate}}</span>
		</div>
		<div class="md:pt-0 pt-5 md:flex-1">
			<label for="confirm_password" class="text-green-dark-900 text-xs pl-2 block mb-1">{{ "SIGN_UP.repeat_password" | translate}} *</label>
			<div class="flex items-center bg-grey-light min-h-[52px] pl-2 border-b border-green-dark-900 pr-2 focus-within:border-green-light-600 transition-colors" [ngClass]="{'border-red-700 pr-2 focus-within:border-red-500':isFieldInvalid(form, 'confirm_password')}">
				<input [type]="hideConfirmPassword?'password':'text'" class="outline-none bg-transparent flex-1" id="confirm_password" formControlName="confirm_password" placeholder="" autocomplete="off">
				<button type="button" class="flex items-center justify-center cursor-pointer" (click)="showConfirmPassword($event)">
					<i class="icon-visibility text-3xl text-green-light-700" *ngIf="hideConfirmPassword"></i>
					<i class="icon-visibility_off text-3xl text-green-light-700" *ngIf="!hideConfirmPassword"></i>
				</button>
			</div>
			<span class="text-red-700 text-xs fon-semibold" *ngIf="isFieldInvalid(form, 'confirm_password')">{{'ERRORS.not_matching_password' | translate}}</span>
		</div>
	</div>
	<div class="md:flex md:gap-12" *ngIf="updateMessage && !updateError" class="text-green">
		{{updateMessage}}
	</div>
</form>
<maia-loader bg-opacity="bg-opacity-20" *ngIf="passUpdating"></maia-loader>