import { createAction, props } from '@ngrx/store';
import { ActionType, DeleteAttachErrorModel, UdriveFileTreeModel, UdriveMailModel, UdriveReadResponseModel } from '@models/udrive.model';


export const setSidePanelOpen = createAction(
	'[StoreDrive] set side panel view',
	props<{ value: boolean }>()
);

export const loadUDrive = createAction(
	'[StoreDrive] load',
	props<{ value: boolean }>()
);

export const getUdrive = createAction(
	'[StoreDrive] Get Drive data'
);

export const setUdrive = createAction(
	'[StoreDrive] Set Drive data',
	props<{ udriveResponse: UdriveReadResponseModel, chatAttachLength: number }>()
);

export const updateUdrive = createAction(
	'[StoreDrive] Update Drive data',
	props<{ updatedFile: UdriveFileTreeModel }>()
);

export const updateNewUdrive = createAction(
	'[StoreDrive] Update Drive new data',
	props<{ updatedFile: UdriveFileTreeModel }>()
);


export const setCurrAction = createAction(
	'[StoreDrive] set current action panel',
	props<{ value: ActionType }>()
);

// export const setCurrProtocolValue = createAction(
// 	'[StoreDrive] set current protocol value',
// 	props<{ value: string }>()
// );

export const setValidInput = createAction(
	'[StoreDrive] set current input value and validity',
	props<{ value: string }>()
);


export const addUdriveFolder = createAction(
	'[StoreDrive] Add Folder'
);

// export const addUdriveFolderOk = createAction(
// 	'[StoreDrive] Add Folder ok'
// );

export const addUdriveUrl = createAction(
	'[StoreDrive] Add Url'
);

export const addUdriveFile = createAction(
	'[StoreDrive] Add File',
	props<{ formData: FormData }>()

);

export const deleteUdriveItem = createAction(
	'[StoreDrive] delete file',
	props<{ value: number }>()
);

export const deleteSelectedAttachments = createAction(
	'[StoreDrive] delete file selected'
);

export const removeUdriveItem = createAction(
	'[StoreDrive] remove files from store',
	props<{ removedFiles: string[] }>()
);

export const selectAll = createAction(
	'[StoreDrive] select all file'
)

export const renameUdriveFolder = createAction(
	'[StoreDrive] rename folder'
);

export const renameUdriveFile = createAction(
	'[StoreDrive] rename file',
);

export const updateFileNotes = createAction(
	'[StoreDrive] update file notes',
	props<{ notes: string[] }>()
)


export const selectFolder = createAction(
	'[StoreDrive] select sub folder',
	props<{ value: string }>()
);


export const setCurrEditedNode = createAction(
	'[StoreDrive] set current edit node',
	props<{ value: UdriveFileTreeModel }>()
);

export const moveUdriveNode = createAction(
	'[StoreDrive] move node',
	props<{ value: UdriveFileTreeModel[] }>()
);

export const toggleMultiSelection = createAction(
	'[StoreDrive] Toggle multi selection'
);

export const selectMultiSelectionItem = createAction(
	'[StoreDrive] Select item for multiselection',
	props<{ value: number }>()
);

export const setMultiSelectionList = createAction(
	'[StoreDrive] add items to multi selection',
	props<{ list: number[], canMultiselect: boolean }>()
);

export const selectExpandedFoldersList = createAction(
	'[StoreDrive] Select item for expand list',
	props<{ value: string }>()
);

export const setExpandedFoldersList = createAction(
	'[StoreDrive] add folders to expanded list',
	props<{ value: string[] }>()
);


export const setFileStatus = createAction(
	'[StoreDrive] set file status value',
	props<{ value: number }>()
);


export const DetachNeeded = createAction(
	'[StoreDrive] open modal for detach managment',
	props<{ value: DeleteAttachErrorModel[] | null }>()
);

export const CurrDetachedChat = createAction(
	'[StoreDrive] Reload curr chat after detach'
);

export const ResetDetach = createAction(
	'[StoreDrive] reset detach status '
);

export const ViewDetachableConv = createAction(
	'[StoreDrive] View conversation from detach modal',
	props<{ value: string }>()
);

export const ChatDetach = createAction(
	'[StoreChat] detach file from chat',
	props<{ detachList: { id: string; title: string | null }[] }>()
);

export const resetAction = createAction(
	'[StoreDrive] reset action statement'
);

// export const urlUploadOk = createAction(
// 	'[StoreDrive] URl upload done'
// );

export const urlUploadError = createAction(
	'[StoreDrive] URl upload error'
);


export const CalculateFileEmbeddings = createAction(
	'[StoreDrive] Calculate file embeddings',
	props<{ attachId: number }>()
);

export const GetEmailAddress = createAction(
	'[StoreDrive] Get permitted mail address'
);

export const SetEmailAddress = createAction(
	'[StoreDrive] Set permitted mail address',
	props<{ emailAddress: UdriveMailModel[] }>()
);

export const AddEmailAddress = createAction(
	'[StoreDrive] Add mail address',
	props<{ newMail: UdriveMailModel }>()
);

export const UpdateEmailAddress = createAction(
	'[StoreDrive] Update mail address',
	props<{ newMail: UdriveMailModel }>()
);

export const DeleteEmailAddress = createAction(
	'[StoreDrive] Delete mail address',
	props<{ mailId: number }>()
);


export const addFakeFolder = createAction(
	'[StoreDrive] add new fake folder'
);

export const cancelFakeFolder = createAction(
	'[StoreDrive] cancel new fake folder'
);




export const createNewChatAttachments = createAction(
	'[StoreDrive] create new conversation with attachment',
	props<{ selectedFile: number[] }>()
);

export const createNewChatQuickAction = createAction(
	'[StoreDrive] create new quick action',
	props<{ action: string, idFile: number, fileName: string, lang?: { code: string, name: string } }>()
);


export const setCurrFiles = createAction(
	'[StoreDrive] current file based on path',
	props<{ currFiles: UdriveFileTreeModel[] }>()
);

export const sortFileList = createAction(
	'[StoreDrive] sortFileList',
	props<{ direction: string, active: string }>()
);

export const resetUdriveState = createAction(
	'[StoreDrive] reset udrive state',
);



export const NoopAction = createAction(
	'[StoreDrive] nothing to do'
);