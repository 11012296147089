<div *ngIf="isMobileView && !isPersonalSetting" class="flex flex-col text-center px-2">
    <span class="text-green-dark-900 text-3xl py-7">{{ "SIGN_UP.more_informations" | translate }}</span>
</div>

<form class="md:pt-5" [ngClass]="!isPersonalSetting  ? 'pt-20' : 'pt-3'" [formGroup]="form" (ngSubmit)="onSubmit.emit()">
    <maia-tag-select [type]="selectType.SINGLE_SELECT" [title]="('SIGN_UP.gender.title' | translate) + '*'" [options]="genders" [selectedOptions]="form.get('sex')?.value" (selectedValues)="selectGender($event)"></maia-tag-select>
    <div class="py-5">
        <maia-tag-select [type]="selectType.SINGLE_SELECT" [title]="('SIGN_UP.chat_language.title' | translate) + '*'" [options]="language" [selectedOptions]="form.get('language')?.value" (selectedValues)="selectLanguage($event)"></maia-tag-select>
    </div>
    <maia-tag-select [type]="selectType.MULTI_SELECT" class="mt-5" [title]="('SIGN_UP.interests.title' | translate) + '*'" [options]="interests" [selectedOptions]="form.get('interests')?.value" (selectedValues)="selectInterest($event)"></maia-tag-select>
</form>