<div class="flex flex-col text-center px-2" *ngIf="!isPersonalSetting">
	<span class="text-green-dark-900 text-3xl py-7">{{ "SIGN_UP.about_you" | translate }}</span>
</div>
<form class="md:pt-3" [formGroup]="form" (ngSubmit)="onSubmit.emit()">
	<div *ngIf="isPersonalSetting" class="md:flex md:gap-12 pb-5">
		<div class="md:flex-1 flex flex-col">
			<label for="email" class="text-green-dark-900 text-xs pl-2 mb-1">{{ "PERSONAL_SETTINGS.INFORMATIONS.email" | translate }}*</label>
			<div class="flex items-center bg-grey-light min-h-[52px] pl-2 border-b border-green-light-900 pr-2 focus-within:border-green-light-600 transition-colors">
				<input type="text" class="outline-none bg-transparent flex-1" id="email" formControlName="email" email>
			</div>
		</div>
		<div class="md:flex-1"></div>
	</div>
	<div class="md:flex md:gap-12">
		<div class="md:flex-1 flex flex-col">
			<label for="name" class="text-green-dark-900 text-xs pl-2 mb-1">{{ "SIGN_UP.first_name" | translate }}*</label>
			<input [tabIndex]="1" type="text" class="bg-grey-light min-h-[52px] pl-2 border-b border-green-dark-900 focus:outline-none focus:border-green-light-600" id="name" formControlName="name">
		</div>
		<div class="md:flex-1 flex flex-col md:pt-0 pt-5">
			<label for="surname" class="text-green-dark-900 text-xs pl-2 mb-1">{{ "SIGN_UP.last_name" | translate }}*</label>
			<input [tabIndex]="2" type="text" class="bg-grey-light min-h-[52px] pl-2 border-b border-green-dark-900 focus:outline-none focus:border-green-light-600" id="surname" formControlName="surname">
		</div>
	</div>
	<div class="md:flex md:gap-12">
		<div class="md:flex-1 flex flex-col pt-5 relative">
			<label for="birthDate" class="text-green-dark-900 text-xs pl-2 mb-1">{{ "SIGN_UP.date_of_birth" | translate }} *</label>
			<button mat-raised-button (click)="picker.open()" class="absolute top-10 left-0 bottom-6 right-0 z-10" type="button"></button>
			<input [matDatepicker]="picker" formControlName="birthDate" [max]="maxBirthday" [min]="minBirthday" class="bg-grey-light min-h-[52px] pl-2 border-b border-green-dark-900 focus:outline-none focus:border-green-light-600" (dateChange)="selectDate('change', $event)" id="birthDate">
			<div class="flex items-center absolute top-[52px] right-[8px] text-2xl text-green-light-700 z-0">
				<i class="icon-calendar"></i>
			</div>
			<mat-datepicker [touchUi]="isMobileView" #picker></mat-datepicker>
			<mat-hint class="text-xs mt-1">DD/MM/YYYY</mat-hint>
			<div *ngIf="form.get('birthDate')!.invalid && form.get('birthDate')!.touched" class="text-red-700 text-xs mt-1">
				{{form.get('birthDate')!.errors!['isAdult']}}
			</div>
		</div>
		<div class="md:flex-1 flex flex-col pt-5">
			<input type="text" [tabIndex]="4" (focus)="select.open()" class="absolute -left-[9999px] -top-[9999px]">
			<label for="country" class="text-green-dark-900 text-xs pl-2 mb-1">{{ "SIGN_UP.country" | translate }} *</label>
			<div class="bg-grey-light pt-3 min-h-[52px] px-2 border-b border-green-dark-900">
				<mat-select #select id="country" formControlName="country" name="country">
					<mat-option *ngFor="let country of countries" [value]="country.code">
						<ng-container [ngSwitch]="country.code">
							<span *ngSwitchCase="'es-US'">(United States) Spanish</span>
							<span *ngSwitchCase="'nl-BE'">Belgium (Dutch)</span>
							<span *ngSwitchCase="'fr-BE'">Belgium (French)</span>
							<span *ngSwitchCase="'en-CA'">Canada (English)</span>
							<span *ngSwitchCase="'fr-CA'">Canada (French)</span>
							<span *ngSwitchDefault>{{country.name}}</span>
						</ng-container>
					</mat-option>
				</mat-select>
			</div>
		</div>
	</div>
</form>