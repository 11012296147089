import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormGroup } from '@angular/forms';
import { COUNTRIES } from '@constants/country-code.constant';
import { DatePipe } from '@angular/common';
import { MobileViewService } from '@services/isMobileView.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'maia-generic-information-section',
	templateUrl: './generic-information-section.component.html',
	styleUrls: ['./generic-information-section.component.scss'],
})
export class GenericInformationSectionComponent implements OnInit {
	@Input() form!: FormGroup;
	@Input() isPersonalSetting: boolean = false;
	@Output() onSubmit: EventEmitter<void> = new EventEmitter();



	countries = COUNTRIES;
	isMobileView: boolean = false;

	public maxBirthday = new Date().toISOString().substring(0, 10);
	public minBirthday = new Date(1900, 0, 1).toISOString().substring(0, 10);
	private destroyed$: Subject<boolean> = new Subject<boolean>();
	constructor(
		private mobileViewService: MobileViewService,
		private datePipe: DatePipe
	) { }

	ngOnInit(): void {
		this.mobileViewService.isMobile.pipe(takeUntil(this.destroyed$)).subscribe({
			next: (isMobileView) => {
				if (isMobileView !== this.isMobileView)
					this.isMobileView = isMobileView;
			},
		});
	}
	selectDate(type: string, event: MatDatepickerInputEvent<Date>) {
		this.form.controls['birthDate'].patchValue(this.datePipe.transform(event.value, 'YYYY-MM-dd'))
	}
}



