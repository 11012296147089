import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'maia-under-maintainance',
	templateUrl: './under-maintainance.component.html',
	styleUrls: ['./under-maintainance.component.scss']
})
export class UnderMaintainanceComponent {
	message: string = '';
	constructor(
		private router: Router
	) {
		this.message = this.router.getCurrentNavigation()!.extras.state!['text'];
	}

	goToMaia(): void {
		document.location.href = document.location.origin;
	}


}
