import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { playerFactory } from 'src/app/app.module';
@NgModule({
    declarations: [
        LoaderComponent
    ],
    imports: [
        CommonModule,
        LottieModule.forRoot({ player: playerFactory }),
        LottieCacheModule.forRoot()
    ],
    exports: [
        LoaderComponent
    ]
})
export class LoaderModule { }