import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MobileViewService } from '@services/isMobileView.service';
import { NavigationService } from '@services/navigation.service';
import { routerTransition } from '@utils/animations';
import { Observable, Subject, combineLatest, debounceTime, takeUntil } from 'rxjs';
import { showBackgroundStateProperty } from '@utils/genericUtils';
import { PreviousUrlService } from '@services/previousUrl.service';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import * as chatSelectors from '@store/selectors/chat.selector';
import { SetCurrPath } from '@store/actions/personalSettings.action';
import { AppRoutes } from '@utils/routes';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [routerTransition()],
})
export class AppComponent implements OnInit, OnDestroy {
	appRoutes = typeof AppRoutes;
	isMobileView: boolean = false;
	showBackgroundImg: boolean = false;
	backgroundUrl: string = '';
	loadingRouteConfig!: boolean;
	updateComponent = environment.develop;
	$isUknowChat: Observable<boolean> = this.store.select(chatSelectors.selectIsUknowChat);
	@HostListener('window:resize', ['$event'])
	onResize() {
		this.mobileViewService.setIsMobile();
	}
	@HostListener('window:orientationchange', ['$event'])
	onOrientationChange() {
		this.mobileViewService.setIsMobile();
	}
	private destroyed$: Subject<boolean> = new Subject<boolean>();
	constructor(
		private translate: TranslateService,
		private navigationService: NavigationService,
		private mobileViewService: MobileViewService,
		private previousUrlService: PreviousUrlService,
		private router: Router,
		private store: Store
	) {
		this.router.events.subscribe((event) => {
			if (event instanceof ActivationEnd) {
				const snapshot = event.snapshot;
				this.showBackgroundImg = snapshot.data[showBackgroundStateProperty];
			}
			if (event instanceof NavigationEnd) {
				let url = event.url;
				if (event.url.indexOf(';') >= 0) {
					url = event.url.replace(event.url.slice(event.url.indexOf(';'), event.url.length), '')
				}
				this.previousUrlService.setPreviousUrl(url);
				this.store.dispatch(SetCurrPath({ currPath: url }))
			}
			if (event instanceof RouteConfigLoadStart) {
				this.loadingRouteConfig = true;
			} else if (event instanceof RouteConfigLoadEnd) {
				this.loadingRouteConfig = false;
			}
		});
		this.mobileViewService.setIsMobile();
	}
	ngOnInit(): void {
		combineLatest([
			this.mobileViewService.isMobile,
			this.store.select(chatSelectors.selectIsUknowChat)
		]).pipe(takeUntil(this.destroyed$), debounceTime(10)).subscribe(res => {
			if (!Boolean(localStorage.getItem('maia_boarded')) && res[0] && !res[1]) {
				this.backgroundUrl = '/assets/images/maia-onboarding-background.jpg';
			} else {
				this.backgroundUrl = '';
			}
			this.isMobileView = res[0]
		});
		this.translate.use(navigator.language.split('-')[0]);
	}

	getRouteAnimation(outlet: RouterOutlet) {
		return this.navigationService.animationValue;
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
}
