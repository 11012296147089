import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CalendarState } from '../app.interface';

export const selectChatFeature = createFeatureSelector<CalendarState>('CalendarState');

export const selectCurrentCalendar = createSelector(
	selectChatFeature,
	(state: CalendarState) => {
		return state.currentCalendar
	}
)

export const selectAvaiableCalendar = createSelector(
	selectChatFeature,
	(state: CalendarState) => {
		return state.availCalendars
	}
)


export const selectCurrentEvents = createSelector(
	selectChatFeature,
	(state: CalendarState) => {
		return state.currEventSettings
	}
)

// export const selectGetEvents = createSelector(
// 	selectChatFeature,
// 	(state: CalendarState) => {
// 		return state.gettingEvents
// 	}
// )

export const selectCurrentEvent = createSelector(
	selectChatFeature,
	(state: CalendarState) => {
		return state.currentEvent
	}
)

export const selectCalendarWaiting = createSelector(
	selectChatFeature,
	(state: CalendarState) => {
		return state.calendarWaiting
	}
)