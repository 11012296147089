import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericInformationSectionComponent } from './generic-information-section.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
	declarations: [GenericInformationSectionComponent],
	exports: [GenericInformationSectionComponent],
	imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatSelectModule
	]
})
export class GenericInformationSectionModule { }
