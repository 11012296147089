export enum SignUpSection {
    MAIL = 'mail',
    CODE = 'code',
    PASSWORD = 'password',
    GENERIC_INFORMATIONS = 'generic-informations',
    INTERESTS = 'interests',
    SOMETHING_ABOUT_YOU = 'something-about-you',
    END_SECTION = 'end-section',
}

export interface VerifyEmailRequest {
    email: string;
}

export interface User extends VerifyEmailRequest {
    totp: string;
    password: string;
    name: string;
    surname: string;
    sex: UserSex;
    address?: string;
    birthDate?: string;
    bio?: string;
    language: ChatLanguage;
    country: string;
    localizationPolicy: boolean,
    mailCalPolicy: boolean,
    microphonePolicy: boolean,
    custMarkPolicy: boolean,
    dataThirdPartPolicy: boolean,
    interests?: string[]
}

export enum UserSex {
    M = 'M',
    F = 'F',
    N = 'N',
}

export enum ChatLanguage {
    IT = 'it',
    FR = 'fr',
    EN = 'en',
}
