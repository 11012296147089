import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, forkJoin, map, of, switchMap } from 'rxjs';
import * as ContactsActions from '../actions/contacts.action';
import { Contact } from '@models/contacts.model';
import { ContactsService } from '@services/contacts.service';
import { Store } from '@ngrx/store';
import { selectCurrentUser } from '@store/selectors/user.selector';
import { DecodedTokenModel } from '@models/decodedToken.model';
import { getContactSettings } from '@store/selectors/personalSettings.selector';
import { ContactsSettings } from '@models/personalSettings.model';

@Injectable()
export class ContactsEffects {
    $user = this.store.select(selectCurrentUser);
    $contactSettings = this.store.select(getContactSettings);
    loadContacts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ContactsActions.LoadContacts),
            switchMap(() => {
                let user: DecodedTokenModel;
                let contactsSettings: ContactsSettings;
                this.$user.subscribe((res) => (user = res));
                this.$contactSettings.subscribe((res) => (contactsSettings = res));
                return this.contactService.getContacts(user!.oid).pipe(
                    map((value: Contact[]) => {
                        const sortValue = contactsSettings.sortBy === 'firstName'
                            ? 'name'
                            : 'surname';
                        return ContactsActions.LoadContactsSuccess({
                            value,
                            sort: sortValue,
                        });
                    }),
                    catchError((error) => {
                        return of(ContactsActions.LoadContactsError({ error }));
                    })
                );
            })
        );
    });

    addContact$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ContactsActions.AddContact),
            switchMap((action) => {
                return this.contactService.addContact(action.value).pipe(
                    map((data) => {
                        //TODO: MAYBE USE A TOAST TO INFORM USER ABOUT CONTACT ADDED
                        return ContactsActions.LoadContacts();
                    }),
                    catchError((error) => {
                        return of(ContactsActions.AddContactError());
                    })
                );
            })
        );
    });

    updateContact$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ContactsActions.UpdateContact),
            switchMap((action) => {
                return this.contactService.updateContact(action.value).pipe(
                    map((data) => {
                        //TODO: MAYBE USE A TOAST TO INFORM USER ABOUT CONTACT ADDED
                        return ContactsActions.LoadContacts();
                    }),
                    catchError((error) => {
                        return of(ContactsActions.UpdateContactError());
                    })
                );
            })
        );
    });

    deleteContact$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ContactsActions.DeleteContact),
            switchMap((action) => {
                return this.contactService.deleteContact(action.value).pipe(
                    switchMap((res) =>
                        of(
                            ContactsActions.DeleteContactSuccess(),
                            ContactsActions.LoadContacts()
                        )
                    ),
                    catchError((error) => {
                        return of(ContactsActions.DeleteContactError());
                    })
                );
            })
        );
    });

    deleteContactList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ContactsActions.DeleteContactList),
            switchMap((action) => {
                const { contactsId, user_id } = action.value;
                return forkJoin(
                    contactsId.map((contact_id) =>
                        this.contactService.deleteContact({ contact_id, user_id })
                    )
                ).pipe(
                    switchMap((res) =>
                        of(
                            ContactsActions.DeleteContactListSuccess(),
                            ContactsActions.LoadContacts()
                        )
                    ),
                    catchError((error) => {
                        return of(ContactsActions.DeleteContactListError());
                    })
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private contactService: ContactsService,
        private store: Store
    ) { }
}
