import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';


export const mobileGuard: CanActivateFn = (route, segments): boolean => {
	let platform = inject(Platform);
	const urlSearchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlSearchParams.entries());


	if (params['nrd'] === 'true') {
		window.localStorage.setItem('maia_nrd', 'true');
	} else if (params['nrd'] && params['nrd'] !== 'true') {
		window.localStorage.removeItem('maia_nrd')
	}

	if (JSON.parse(localStorage.getItem('maia_nrd')!)) {
		return true
	} else {
		if (platform.IOS) {
			window.location.href = 'https://apps.apple.com/it/app/maia/id6479750749';
			return false
		} else if (platform.ANDROID) {
			window.location.href = 'https://play.google.com/store/apps/details?id=ai.mymaia.maia&hl=it&gl=US';
			return false
		} else {
			return true;
		}
	}
}