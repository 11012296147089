export const COUNTRIES = [
	{ name: "Argentina", code: "es-AR" },
	{ name: "Australia", code: "en-AU" },
	{ name: "Austria", code: "de-AT" },
	{ name: "Belgium", code: "nl-BE" },
	{ name: "Belgium", code: "fr-BE" },
	{ name: "Brazil", code: "pt-BR" },
	{ name: "Canada", code: "en-CA" },
	{ name: "Canada", code: "fr-CA" },
	{ name: "Chile", code: "es-CL" },
	{ name: "Denmark", code: "da-DK" },
	{ name: "Finland", code: "fi-FI" },
	{ name: "France", code: "fr-FR" },
	{ name: "Germany", code: "de-DE" },
	{ name: "Hong Kong SAR", code: "zh-HK" },
	{ name: "India", code: "en-IN" },
	{ name: "Indonesia", code: "en-ID" },
	{ name: "Italy", code: "it-IT" },
	{ name: "Japan", code: "ja-JP" },
	{ name: "Korea", code: "ko-KR" },
	{ name: "Malaysia", code: "en-MY" },
	{ name: "Mexico", code: "es-MX" },
	{ name: "Netherlands", code: "nl-NL" },
	{ name: "New Zealand", code: "en-NZ" },
	{ name: "Norway", code: "no-NO" },
	{ name: "People's republic of China", code: "zh-CN" },
	{ name: "Poland", code: "pl-PL" },
	{ name: "Portugal", code: "pt-PT" },
	{ name: "Republic of the Philippines", code: "en-PH" },
	{ name: "Russia", code: "ru-RU" },
	{ name: "South Africa", code: "en-ZA" },
	{ name: "Spain", code: "es-ES" },
	{ name: "Sweden", code: "sv-SE" },
	{ name: "Switzerland", code: "fr-CH" },
	{ name: "Switzerland", code: "de-CH" },
	{ name: "Taiwan", code: "zh-TW" },
	{ name: "Turkey", code: "tr-TR" },
	{ name: "United Kingdom", code: "en-GB" },
	{ name: "United States", code: "en-US" },
	{ name: "United States", code: "es-US" },
	{ name: "Other", code: "en-US" },
];