import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { MobileViewService } from '@services/isMobileView.service';
import { GenericUtils } from '@utils/genericUtils';

import { getInformation } from '@store/selectors/personalSettings.selector';
import { UserInformations } from '@models/setting.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'maia-password-section',
    templateUrl: './password-section.component.html',
    styleUrls: ['./password-section.component.scss'],
})
export class PasswordSectionComponent implements OnInit, OnDestroy {
    @Input() form!: FormGroup;
    @Input() isPersonalSetting: boolean = false;
    @Input() hideTitleSection: boolean = false;
    @Output() onSubmit: EventEmitter<void> = new EventEmitter();

    @Output() onFormChange: EventEmitter<void> = new EventEmitter();
    @Output() onStatusChange: EventEmitter<boolean> = new EventEmitter();
    @Input() onSave: Observable<void> = new Observable();

    public isFieldInvalid = GenericUtils.isFieldInvalid;
    hidePassword: boolean = true;
    hideConfirmPassword: boolean = true;
    hideOldPassword: boolean = true;
    isMobileView: boolean = false;

    currUser: Observable<UserInformations | null> =
        this.store.select(getInformation);
    updateMessage: string | null = null;
    passUpdating: boolean = false;
    updateError: boolean = false;
    private destroyed$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private mobileViewService: MobileViewService,
        private authService: AuthService,
        private store: Store,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.mobileViewService.isMobile.pipe(takeUntil(this.destroyed$)).subscribe({
            next: (isMobileView) => (this.isMobileView = isMobileView),
        });

        this.form.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.form.touched && this.onFormChange.emit());
        this.form.statusChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.onStatusChange.emit(this.form.valid));
        this.onSave
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.changePassword());
    }

    showPassword($event: Event) {
        $event.stopImmediatePropagation();
        this.hidePassword = !this.hidePassword;
    }

    showConfirmPassword($event: Event) {
        $event.stopImmediatePropagation();
        this.hideConfirmPassword = !this.hideConfirmPassword;
    }

    showOldPassword($event: Event) {
        $event.stopImmediatePropagation();
        this.hideOldPassword = !this.hideOldPassword;
    }

    getPasswordRequirementLabelStyle(errorToControl: string) {
        if (!this.form.get('password')?.dirty) return 'text-grey-dark';
        return this.form.get('password')?.hasError(errorToControl)
            ? 'text-red-700'
            : 'text-green-dark-900';
    }

    getPasswordRequirementBoxStyle(errorToControl: string) {
        if (!this.form.get('password')?.dirty) return 'border-grey-dark';
        return this.form.get('password')?.hasError(errorToControl)
            ? 'border-red'
            : 'border-green-dark-900';
    }

    isPasswordRequirementControlFulfilled(errorToControl: string) {
        if (!this.form.get('password')?.dirty) return false;
        return !this.form.get('password')?.hasError(errorToControl);
    }

    isPasswordRequirementControlError(errorToControl: string) {
        return (
            this.form.get('password')?.dirty &&
            this.form.get('password')?.hasError(errorToControl)
        );
    }

    changePassword() {
        this.currUser.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
            this.updateError = false;
            this.passUpdating = true;
            this.updateMessage = null;
            let updateObj = {
                authorization: window.btoa(
                    `${user?.email}:${this.form.value.old_password}`
                ),
                password: window.btoa(`${this.form.value.confirm_password}`),
            };
            this.authService.updatePassword(updateObj).subscribe({
                next: (res) => {
                    this.updateMessage = this.translate.instant(
                        'PERSONAL_SETTINGS.SECURITY.update_pass'
                    );
                    this.passUpdating = false;
                },
                error: (errorRes) => {
                    this.updateMessage = this.translate.instant(
                        'PERSONAL_SETTINGS.SECURITY.not_update_pass'
                    );
                    this.passUpdating = false;
                    this.updateError = true;
                },
            });
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
