import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { TagSelectOption, TagSelectType } from '@models/generic.model';
import { ChatLanguage, UserSex } from '@models/signUp.model';
import { MobileViewService } from '@services/isMobileView.service';

@Component({
    selector: 'maia-interests-section',
    templateUrl: './interests-section.component.html',
    styleUrls: ['./interests-section.component.scss'],
})
export class InterestsSectionComponent implements OnInit, OnDestroy {
    @Input() form!: FormGroup;
    @Input() isPersonalSetting: boolean = false;
    @Output() onSubmit: EventEmitter<void> = new EventEmitter();

    isMobileView: boolean = false;
    private destroyed$: Subject<boolean> = new Subject<boolean>();

    selectType: typeof TagSelectType = TagSelectType;
    genders: Array<TagSelectOption> = [
        { id: UserSex.M, name: this.translate.instant('SIGN_UP.gender.male') },
        { id: UserSex.F, name: this.translate.instant('SIGN_UP.gender.female') },
        { id: UserSex.N, name: this.translate.instant('SIGN_UP.gender.other') },
    ];
    language: Array<TagSelectOption> = [
        { id: ChatLanguage.IT, name: this.translate.instant('SIGN_UP.chat_language.italian') },
        { id: ChatLanguage.EN, name: this.translate.instant('SIGN_UP.chat_language.english') },
        { id: ChatLanguage.FR, name: this.translate.instant('SIGN_UP.chat_language.french') },
    ];
    interests: Array<TagSelectOption> = [
        { id: 'sport', name: this.translate.instant('SIGN_UP.interests.sport') },
        { id: 'movies', name: this.translate.instant('SIGN_UP.interests.movies') },
        { id: 'music', name: this.translate.instant('SIGN_UP.interests.music') },
        { id: 'fashion', name: this.translate.instant('SIGN_UP.interests.fashion') },
        { id: 'food', name: this.translate.instant('SIGN_UP.interests.food') },
        { id: 'motors', name: this.translate.instant('SIGN_UP.interests.motors') },
        { id: 'video_games', name: this.translate.instant('SIGN_UP.interests.video_games') },
        { id: 'politics', name: this.translate.instant('SIGN_UP.interests.politics') },
        { id: 'books', name: this.translate.instant('SIGN_UP.interests.books') },
    ];

    constructor(
        private mobileViewService: MobileViewService,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.mobileViewService.isMobile.pipe(takeUntil(this.destroyed$)).subscribe({
            next: (isMobileView) => (this.isMobileView = isMobileView),
        });
    }

    selectGender(sex: TagSelectOption[]) {
        this.form.patchValue({
            ...this.form.value,
            sex,
        });
    }

    selectLanguage(language: TagSelectOption[]) {
        this.form.patchValue({
            ...this.form.value,
            language,
        });
    }

    selectInterest(interests: TagSelectOption[]) {
        this.form.patchValue({
            ...this.form.value,
            interests,
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
