import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { MobileViewService } from '@services/isMobileView.service';

@Component({
    selector: 'maia-about-you-section',
    templateUrl: './about-you-section.component.html',
    styleUrls: ['./about-you-section.component.scss'],
})
export class AboutYouSectionComponent implements OnInit, OnDestroy {
    @Input() form!: FormGroup;
    @Input() isPersonalSetting: boolean = false;
    @Output() onSubmit: EventEmitter<void> = new EventEmitter();

    isMobileView: boolean = false;
    private destroyed$: Subject<boolean> = new Subject<boolean>();

    constructor(private mobileViewService: MobileViewService) { }

    ngOnInit(): void {
        this.mobileViewService.isMobile.pipe(takeUntil(this.destroyed$)).subscribe({
            next: (isMobileView) => (this.isMobileView = isMobileView),
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
