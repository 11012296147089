import { Component, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval, Subscription } from 'rxjs';

@Component({
	selector: 'maia-update-app',
	templateUrl: './update-app.component.html',
	styleUrls: ['./update-app.component.scss']
})
export class UpdateAppComponent {
	//updateFound!: boolean;
	isNewVersionAvailable!: boolean;
	intervalSource = interval(10 * 60 * 1000); //ogni 10 minuti
	newVersionSubscription!: Subscription;
	intervalSubscription!: Subscription;
	constructor(
		private swUpdate: SwUpdate,
		private zone: NgZone
	) {
		this.checkForUpdate();
		this.checkForUpdateInterval();
	}

	checkForUpdate(): void {
		this.newVersionSubscription?.unsubscribe();
		if (!this.swUpdate.isEnabled) {
			return;
		}
		this.newVersionSubscription = this.swUpdate.versionUpdates.subscribe(evt => {
			switch (evt.type) {
				case 'VERSION_DETECTED':
					console.log(`Downloading new app version: ${evt.version.hash}`);
					break;
				case 'VERSION_READY':
					console.log(`Current app version: ${evt.currentVersion.hash}`);
					console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
					this.isNewVersionAvailable = true;
					break;
				case 'VERSION_INSTALLATION_FAILED':
					console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
					break;
			}
		});

		// this.intervalSubscription?.unsubscribe();
		// if (!this.swUpdate.isEnabled) {
		// 	return;
		// }

		// this.zone.runOutsideAngular(() => {
		// 	this.intervalSubscription = this.intervalSource.subscribe(async () => {
		// 		try {
		// 			this.isNewVersionAvailable = await this.swUpdate.checkForUpdate();
		// 			console.log(this.isNewVersionAvailable ? 'A new version is available.' : 'Already on the latest version.');
		// 		} catch (error) {
		// 			console.error('Failed to check for updates:', error);
		// 		}
		// 	});
		// })
	}

	checkForUpdateInterval(): void {
		this.intervalSubscription?.unsubscribe();
		if (!this.swUpdate.isEnabled) {
			return;
		}

		this.zone.runOutsideAngular(() => {
			this.intervalSubscription = this.intervalSource.subscribe(async () => {
				try {
					this.isNewVersionAvailable = await this.swUpdate.checkForUpdate();
					console.log(this.isNewVersionAvailable ? 'A new version is available.' : 'Already on the latest version.');
				} catch (error) {
					console.error('Failed to check for updates:', error);
				}
			});
		})
	}


	refreshApp() {
		this.swUpdate.activateUpdate()
			.then(() => document.location.reload())
			.catch(error => console.error('Failed to apply updates:', error));
	}

}
