import { createReducer, on, Action } from '@ngrx/store';
import { TextToSpeechState, initialAppState } from '../app.interface';
import * as actions from '@store/actions/tts.action';
export const reducer = createReducer(
    initialAppState.TextToSpeechState,
    on(actions.SetWsConnectionID, (state, payload): TextToSpeechState => {
        return {
            ...state,
            wsConnectionID: payload.value
        }
    }),
    on(actions.EnablingTts, (state, payload): TextToSpeechState => {
        return {
            ...state,
            ttsEnabled: payload.value
        }
    }),
    on(actions.SetWsConnectionID, (state, payload): TextToSpeechState => {
        return {
            ...state,
            wsConnectionID: payload.value
        }
    }),
    on(actions.NewTtsStart, (state, payload): TextToSpeechState => {
        return {
            ...state,
            ttsCurrAnswer: payload.value.id_answer,
            ttsSample: []
        }
    }),
    on(actions.NewTts, (state, payload): TextToSpeechState => {
        let endedTts: number[] = [...state.endedTts];
        endedTts.splice(state.ttsIgnoreAnswer.indexOf(payload.value.id_answer), 1);
        return {
            ...state,
            ttsIsPlaying: true,
            ttsSample: [...state.ttsSample, `data:audio/wav;base64,${payload.value.msg}`],
            endedTts: [...endedTts]
        }
    }),

    on(actions.SetBanTts, (state): TextToSpeechState => {
        let endedTts: number[] = [...state.endedTts];
        if (state.endedTts.indexOf(state.ttsCurrAnswer) >= 0) {
            endedTts.splice(state.ttsIgnoreAnswer.indexOf(state.ttsCurrAnswer), 1);
            return {
                ...state,
                ttsIsPlaying: false,
                endedTts: [...endedTts]
            }
        } else {
            return {
                ...state,
                ttsIsPlaying: false,
                ttsIgnoreAnswer: [...state.ttsIgnoreAnswer, state.ttsCurrAnswer]
            }
        }
    }),

    on(actions.ClearTts, (state): TextToSpeechState => {
        return {
            ...state,
            ttsIsPlaying: false,
            ttsSample: [],
            ttsCurrAnswer: 0,
            endedTts: []
        }
    }),
    on(actions.SetCurrAnswer, (state, payload): TextToSpeechState => {
        return {
            ...state,
            ttsCurrAnswer: payload.value
        }
    }),
    on(actions.NewTtsEnd, (state, payload): TextToSpeechState => {
        let bannedCat: number[] = [...state.ttsIgnoreAnswer];
        if (state.ttsIgnoreAnswer.indexOf(payload.value.id_answer) >= 0) {
            bannedCat.splice(state.ttsIgnoreAnswer.indexOf(payload.value.id_answer), 1);
        }
        return {
            ...state,
            ttsIgnoreAnswer: [...bannedCat],
            endedTts: [...state.endedTts, payload.value.id_answer]
        }
    })
);


export function TextToSpeechReducer(state: TextToSpeechState | undefined, action: Action): TextToSpeechState {
    return reducer(state as TextToSpeechState, action as Action);
}