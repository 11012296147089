import { Component, EventEmitter, HostBinding, Input, Output, inject } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { ButtonType } from '@models/generic.model';

@Component({
    selector: 'maia-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    @Input() label: string = '';
    @Input() type: 'button' | 'submit' = 'button';
    @Input() btnType: ButtonType = ButtonType.PRIMARY;

    @Input() customClass: string = 'select-none';
    @Output() onClick: EventEmitter<void> = new EventEmitter();

    @Input() spinnerStatus: boolean = false;

    @HostBinding('class.pointer-events-none') @Input() isDisabled: boolean = false;


    defaultRoundedBtnClasses: string = 'w-full rounded-lg font-bold px-3';
    defaultUnderlinedBtnClasses: string = 'w-full font-bold px-3';


    options: AnimationOptions = { path: '/assets/images/spinner_m.json' };

    getClass(): string {
        let className: string = '';

        switch (this.btnType) {
            case ButtonType.TEXT:
                className = `${this.defaultUnderlinedBtnClasses} py-2.5 ${this.isDisabled
                    ? 'text-grey-medium border-b border-grey-medium'
                    : 'text-green-dark-900 border-b border-green-dark-900 cursor-pointer'
                    } ${this.customClass}`;
                break;
            case ButtonType.PRIMARY:
                className = `${this.defaultRoundedBtnClasses} py-2.5 ${this.isDisabled
                    ? 'text-black bg-grey-medium'
                    : 'text-white bg-green-light cursor-pointer'
                    } ${this.customClass}`;
                break;
            case ButtonType.PRIMARY_EMPTY:
                className = `${this.defaultRoundedBtnClasses} py-2 border-2 bg-white ${this.isDisabled
                    ? 'text-grey-dark border-grey-dark'
                    : 'text-green-light-700 border-green-medium cursor-pointer'
                    } ${this.customClass}`;
                break;
            case ButtonType.SECONDARY:
                className = `${this.defaultRoundedBtnClasses} py-2.5 ${this.isDisabled
                    ? 'text-black bg-grey-medium'
                    : 'text-white bg-green-light-900 cursor-pointer'
                    } ${this.customClass}`;
                break;
            case ButtonType.SECONDARY_EMPTY:
                className = `${this.defaultRoundedBtnClasses} py-2 border-2 bg-white ${this.isDisabled
                    ? 'text-grey-dark border-grey-dark'
                    : 'text-green-dark-900 border-green-dark-900 cursor-pointer'
                    } ${this.customClass}`;
                break;
            case ButtonType.TERTIARY:
                className = `${this.defaultRoundedBtnClasses} py-2.5 ${this.isDisabled
                    ? 'text-black bg-grey-medium'
                    : 'text-white bg-green-light-700/50 cursor-pointer'
                    } ${this.customClass}`;
                break;
            case ButtonType.TERTIARY_EMPTY:
                className = `${this.defaultRoundedBtnClasses} py-2 border-2 bg-white ${this.isDisabled
                    ? 'text-grey-dark border-grey-dark'
                    : 'text-green-light-700/50 border-green-medium/50 cursor-pointer'
                    } ${this.customClass}`;
                break;
            case ButtonType.DANGER:
                className = `${this.defaultRoundedBtnClasses} py-2.5 ${this.isDisabled ? 'text-white bg-red/50' : 'text-white bg-red cursor-pointer'
                    } ${this.customClass}`;
                break;
            default:
                className = '';
                break;
        }

        return className;
    }
}
