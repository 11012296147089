import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignUpSection, User, VerifyEmailRequest } from '@models/signUp.model';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppRoutes } from '@utils/routes';
import { Regex } from '@utils/regex';
import { GenericUtils } from '@utils/genericUtils';
import { StepperModule } from '../stepper/stepper.module';
import { Subject, takeUntil } from 'rxjs';
import { ButtonType, ErrorApi } from '@models/generic.model';
import { ButtonModule } from '../button/button.module';
import { AuthService } from '@services/auth.service';
import { slideInFromRightAnimation, slideInFromLeftAnimation } from '@utils/animations';
import { PasswordSectionModule } from './password-section/password-section.module';
import { GenericInformationSectionModule } from './generic-information-section/generic-information-section.module';
import { InterestSectionModule } from './interests-section/interests-section.module';
import { AboutYouSectionModule } from './about-you-section/about-you-section.module';
import { MobileViewService } from '@services/isMobileView.service';
import { LastStepSectionModule } from './last-step-section/last-step-section.module';
import { LocalStorageService } from '@services/locaStorage.service';

@Component({
	selector: 'maia-sign-up',
	standalone: true,
	imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, StepperModule, ButtonModule, PasswordSectionModule, GenericInformationSectionModule, InterestSectionModule, AboutYouSectionModule, LastStepSectionModule],
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.scss'],
	animations: [slideInFromRightAnimation, slideInFromLeftAnimation],
})
export class SignUpComponent implements OnInit, OnDestroy {
	public isAnimated = GenericUtils.conditionalAnimationDuration;
	section: typeof SignUpSection = SignUpSection;
	btnType: typeof ButtonType = ButtonType;
	currentSection: SignUpSection = SignUpSection.MAIL;
	prevSection: SignUpSection = SignUpSection.MAIL;
	done: boolean = false;
	accessError: boolean = false;
	currIndex: number = 0;
	verifyEmailForm: FormGroup;
	verifyCodeForm: FormGroup;
	addPasswordForm: FormGroup;
	genericInformationForm: FormGroup;
	interestsForm: FormGroup;
	aboutYouForm: FormGroup;
	isMobileView: boolean = false;
	verifingEmail: boolean = false;
	creatingUser: boolean = false;
	private destroyed$: Subject<boolean> = new Subject<boolean>();
	next: Subject<void> = new Subject<void>();
	prev: Subject<void> = new Subject<void>();
	innerHeight!: number
	constructor(
		private router: Router,
		private authService: AuthService,
		private mobileViewService: MobileViewService,
		private localSotrage: LocalStorageService
	) {
		this.verifyEmailForm = new FormGroup({
			verify_email: new FormControl('', [
				Validators.required,
				Validators.pattern(Regex.REGEX_EMAIL),
			]),
			privacy: new FormControl(false, [Validators.requiredTrue]),
			personalData: new FormControl(false),
			localizationPolicy: new FormControl(false),
			mailCalPolicy: new FormControl(false),
			microphonePolicy: new FormControl(false),
			custMarkPolicy: new FormControl(false),
			dataThirdPartPolicy: new FormControl(false),
			tos: new FormControl(false, [Validators.requiredTrue]),
			clauses: new FormControl(false, [Validators.requiredTrue]),
		});
		this.verifyCodeForm = new FormGroup({
			code: new FormControl('', [
				Validators.required,
				Validators.minLength(6),
				Validators.pattern(Regex.NUMBER_VALIDATOR),
			]),
		});
		this.addPasswordForm = new FormGroup({
			password: new FormControl('', [
				Validators.required,
				Validators.minLength(10),
				GenericUtils.patternValidator(Regex.PASSWORD_NUMBER_VALIDATOR, {
					numberMissing: true,
				}),
				GenericUtils.patternValidator(Regex.PASSWORD_UPPERCASE_VALIDATOR, {
					upperCaseMissing: true,
				}),
				GenericUtils.patternValidator(Regex.PASSWORD_LOWERCASE_VALIDATOR, {
					lowerCaseMissing: true,
				}),
				GenericUtils.patternValidator(
					Regex.PASSWORD_SPECIAL_CHARACTER_VALIDATOR,
					{ specialCharacterMissing: true }
				),
			]),
			confirm_password: new FormControl('', [
				Validators.required,
				GenericUtils.matchOtherValidator('password'),
			]),
		});
		this.genericInformationForm = new FormGroup({
			name: new FormControl('', [Validators.required]),
			surname: new FormControl('', [Validators.required]),
			birthDate: new FormControl('', [Validators.required, GenericUtils.isAdult()]),
			country: new FormControl('', [Validators.required]),
		});
		this.interestsForm = new FormGroup({
			sex: new FormControl([], [Validators.required]),
			language: new FormControl([], [Validators.required]),
			interests: new FormControl([], [Validators.required]),
		});
		this.aboutYouForm = new FormGroup({
			bio: new FormControl('', []),
		});

	}
	@HostListener('window:orientationchange', ['$event'])
	@HostListener('window:resize', ['$event'])
	onResize($event: Event) {
		if ($event.isTrusted) this.getHeight();
	}


	ngOnInit(): void {
		this.mobileViewService.isMobile.pipe(takeUntil(this.destroyed$)).subscribe({
			next: (isMobileView) => {
				this.isMobileView = isMobileView
			}
		});
		this.getHeight();
	}

	getHeight() {
		this.innerHeight = window.innerHeight;
	}

	getStepperStatus(event: { index: number; done: boolean }) {
		this.done = event.done;
		this.currIndex = event.index;
		switch (event.index) {
			case 0:
				this.changeSection(SignUpSection.PASSWORD);
				break;
			case 1:
				this.changeSection(SignUpSection.GENERIC_INFORMATIONS);
				break;
			case 2:
				this.changeSection(SignUpSection.INTERESTS);
				break;
			case 3:
				this.changeSection(SignUpSection.SOMETHING_ABOUT_YOU);
				break;
			// case 4:
			//     this.router.navigate([`/${AppRoutes.WELCOME}`]);
			//     //this.changeSection(SignUpSection.END_SECTION);
			//     break;

			default:
				this.changeSection(SignUpSection.PASSWORD);
				break;
		}
	}

	onNext() {
		if (!this.done) {
			this.currentSection === SignUpSection.PASSWORD && this.addPassword();
			if (this.currentSection === SignUpSection.SOMETHING_ABOUT_YOU)
				this.createUser();
			else this.next.next();
		} else {
			this.router.navigate([`/${AppRoutes.AUTH}`]);
		}
	}
	onPrev() {
		this.prev.next();
	}

	verifyEmail() {
		this.verifingEmail = true;
		//this.errorVerifing = '';
		const body: VerifyEmailRequest = {
			email: this.verifyEmailForm.value.verify_email,
		};
		this.authService
			.signUp(body)
			.pipe(takeUntil(this.destroyed$))
			.subscribe({
				next: () => {
					this.verifingEmail = false;
					this.changeSection(SignUpSection.CODE);
				},
				error: (res) => {
					this.verifingEmail = false;
					this.manageError(res.error.errorCode)
				},
			});
	}

	createUser() {
		this.creatingUser = true;
		const body: User = {
			email: this.verifyEmailForm.value.verify_email,
			totp: this.verifyCodeForm.value.code,
			password: window.btoa(this.addPasswordForm.value.password),
			name: this.genericInformationForm.value.name,
			surname: this.genericInformationForm.value.surname,
			birthDate: this.genericInformationForm.value.birthDate,
			country: this.genericInformationForm.value.country,
			sex: this.interestsForm.value.sex[0].id,
			language: this.interestsForm.value.language[0].id,
			localizationPolicy: this.verifyEmailForm.value.localizationPolicy,
			mailCalPolicy: this.verifyEmailForm.value.mailCalPolicy,
			microphonePolicy: this.verifyEmailForm.value.microphonePolicy,
			custMarkPolicy: this.verifyEmailForm.value.custMarkPolicy,
			dataThirdPartPolicy: this.verifyEmailForm.value.dataThirdPartPolicy,
			interests: this.interestsForm.value.interests.map(
				(item: { id: string; name: string }) => {
					return item.id;
				}
			),
			bio: this.aboutYouForm.value.bio,
			address: ''
		};
		this.authService
			.signUp(body)
			.pipe(takeUntil(this.destroyed$))
			.subscribe({
				next: () => {
					this.tmpUser();
					this.router.navigate([`/${AppRoutes.WELCOME}`]);
				},
				error: (res) => {
					this.creatingUser = false;
					this.manageError(res.error.errorCode);
				},
			});
	}


	tmpUser(): void {
		this.localSotrage.setItem('maia_tmpUser', {
			personName: this.genericInformationForm.value.name,
			email: this.verifyEmailForm.value.verify_email,
			password: this.addPasswordForm.value.password
		})

	}

	addPassword() {
		if (this.addPasswordForm.valid) {
			this.changeSection(SignUpSection.GENERIC_INFORMATIONS);
		} else {
			Object.values(this.addPasswordForm.controls).forEach((control) => {
				control.markAsTouched();
			});
		}
	}

	changeSection(nextSection: SignUpSection) {
		this.prevSection = this.currentSection;
		this.currentSection = nextSection;
	}

	goBack() {
		switch (this.currentSection) {
			case SignUpSection.MAIL:
				this.router.navigate([`/${AppRoutes.AUTH}`]);
				break;
			case SignUpSection.CODE:
				this.changeSection(SignUpSection.MAIL);
				break;
			case SignUpSection.PASSWORD:
				this.changeSection(SignUpSection.CODE);
				break;

			default:
				this.onPrev();
				break;
		}
	}

	isNextDisabled() {
		switch (this.currentSection) {
			case SignUpSection.PASSWORD:
				return !this.addPasswordForm.valid;
			case SignUpSection.GENERIC_INFORMATIONS:
				return !this.genericInformationForm.valid;
			case SignUpSection.INTERESTS:
				return !this.interestsForm.valid;

			default:
				return false;
		}
	}

	isCreateAccountDisabled() {
		return (
			!this.addPasswordForm.valid ||
			!this.genericInformationForm.valid ||
			!this.interestsForm.valid ||
			!this.aboutYouForm.valid
		);
	}

	onPersonalDataChange(event: Event) {
		this.verifyEmailForm.patchValue({
			...this.verifyEmailForm.value,
			personalData: (event.target as HTMLInputElement).checked,
			localizationPolicy: (event.target as HTMLInputElement).checked,
			mailCalPolicy: (event.target as HTMLInputElement).checked,
			microphonePolicy: (event.target as HTMLInputElement).checked,
			custMarkPolicy: (event.target as HTMLInputElement).checked,
			dataThirdPartPolicy: (event.target as HTMLInputElement).checked
		});
	}



	onTosChange(event: Event) {
		this.verifyEmailForm.patchValue({
			...this.verifyEmailForm.value,
			tos: (event.target as HTMLInputElement).checked,
		});
	}
	onClausesChange(event: Event) {
		this.verifyEmailForm.patchValue({
			...this.verifyEmailForm.value,
			clauses: (event.target as HTMLInputElement).checked,
		});
	}
	onLocChange(event: Event) {
		this.verifyEmailForm.patchValue({
			...this.verifyEmailForm.value,
			personalData: (event.target as HTMLInputElement).checked,
			localizationPolicy: (event.target as HTMLInputElement).checked,
		});
		this.verifyPersonalData();
	}
	onSyncChange(event: Event) {
		this.verifyEmailForm.patchValue({
			...this.verifyEmailForm.value,
			personalData: (event.target as HTMLInputElement).checked,
			mailCalPolicy: (event.target as HTMLInputElement).checked,
		});
		this.verifyPersonalData();
	}
	onMicChange(event: Event) {
		this.verifyEmailForm.patchValue({
			...this.verifyEmailForm.value,
			personalData: (event.target as HTMLInputElement).checked,
			microphonePolicy: (event.target as HTMLInputElement).checked,
		});
		this.verifyPersonalData();
	}
	onMktChange(event: Event) {
		this.verifyEmailForm.patchValue({
			...this.verifyEmailForm.value,
			personalData: (event.target as HTMLInputElement).checked,
			custMarkPolicy: (event.target as HTMLInputElement).checked,
		});
		this.verifyPersonalData();
	}
	onAssChange(event: Event) {
		this.verifyEmailForm.patchValue({
			...this.verifyEmailForm.value,
			personalData: (event.target as HTMLInputElement).checked,
			dataThirdPartPolicy: (event.target as HTMLInputElement).checked,
		});
		this.verifyPersonalData();
	}

	private verifyPersonalData() {
		if (
			this.verifyEmailForm.value.localizationPolicy === false &&
			this.verifyEmailForm.value.mailCalPolicy === false &&
			this.verifyEmailForm.value.microphonePolicy === false &&
			this.verifyEmailForm.value.custMarkPolicy === false &&
			this.verifyEmailForm.value.dataThirdPartPolicy === false
		) {
			this.verifyEmailForm.patchValue({
				...this.verifyEmailForm.value,
				personalData: false
			});
		} else {
			this.verifyEmailForm.patchValue({
				...this.verifyEmailForm.value,
				personalData: true
			});
		}
	}


	private manageError(res: string) {
		switch (res) {
			case ErrorApi.EXI_USR:
				this.verifyEmailForm.controls['verify_email'].setErrors({ userExist: true });
				break;
			case ErrorApi.INV_TOTP:
				this.changeSection(SignUpSection.CODE);
				setTimeout(() => {
					this.verifyCodeForm.controls['code'].setErrors({ invalidTotp: true });
				}, 300)
				break;
		}
	}


	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
}
