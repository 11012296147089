import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { playerFactory } from 'src/app/app.module';


@NgModule({
	declarations: [ButtonComponent],
	exports: [ButtonComponent],
	imports: [CommonModule,
		LottieModule.forRoot({ player: playerFactory }),
		LottieCacheModule.forRoot()],
	providers: []

})
export class ButtonModule { }
