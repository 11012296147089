import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatState } from '../app.interface';
import { ResponseCategoryUiCardType } from '@models/chat.model';

export const selectChatFeature = createFeatureSelector<ChatState>('ChatState');

export const selectChat = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		let object = {
			chatId: state.chatId,
			chatTitle: state.chatTitle,
			conversationType: ResponseCategoryUiCardType.DIALOG,
			isNew: false,
			shareDate: state.shareDate,
			hasAttachments: state.hasAttachments,
			disableWebSearch: state.disableWebSearch,
			hasUKnow: state.hasUKnow
		};
		if (state.chatList) {
			let chat = state.chatList.find((chat) => chat.convId === state.chatId);
			if (chat) {
				object = {
					...object,
					conversationType: chat.conversationType,
					isNew: chat.lastMsgTime !== '' ? false : true,
				}
			}
		}
		return object
	}
);

export const selectChatList = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.chatList
	}
);

export const selecChatSettId = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.chatList;
	}
);
export const selectChatMSg = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.currMessages
	}
);

export const selectChatHistory = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.currHistory
	}
);

export const incomingMsg = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.incomingMsg
	}
);

export const loadChat = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.loadChat
	}
);


export const selectChatId = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.chatId
	}
);

export const selectChatHasAttachments = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.hasAttachments
	}
);

export const selectChatAttachments = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.attachments
	}
);

export const selectIsUknowChat = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.isUknowChat
	}
);

export const selectUknowWillDeleted = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.uknowWillDeleted
	}
);

export const selectSentByuser = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.sentByUser
	}
);

export const loadMoreChat = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.chatToLoad
	}
);

export const searchChatStatus = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.searchChat
	}
);

export const selectChatToDel = createSelector(
	selectChatFeature,
	(state: ChatState) => {
		return state.chatToDel
	}
);