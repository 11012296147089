import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, tap, withLatestFrom } from 'rxjs';
import * as personalActions from '../actions/personalSettings.action';


import * as personalSelectors from '@store/selectors/personalSettings.selector';
import * as userSelectors from '@store/selectors/user.selector';
import * as chatSelectors from '@store/selectors/chat.selector';
import { SettingService } from '@services/setting.service';
import { Store } from '@ngrx/store';
import { SettingModel, SettingNotification, SourceManagement, SettingsKeyName } from '@models/setting.model';
import { ContactsSettings, MaiaApplicationPreferences } from '@models/personalSettings.model';
import { LastPPGUpdate } from '../actions/personalGraph.action';

export const localStorageVisualPreferenceKey = 'visual_preferences';

@Injectable()
export class PersonalSettingsEffects {

    GetSettings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(personalActions.GetSettings),
            withLatestFrom(
                this.store.select(userSelectors.selectCurrentUser)
            ),
            exhaustMap(([action, currUser]) => {
                return this.settingService.getSettingsByIdUser(action.value).pipe(
                    map((res) => {
                        if (res.some((el: SettingModel) => el.key === SettingsKeyName.GOOGLE_TOKEN)) {
                            this.settingService.getGoogleCalendars(currUser.oid).subscribe((res) => {
                                this.store.dispatch(personalActions.SetCalendars({ value: res }));
                            });
                        }
                        return res
                    }),
                    map((res) => {
                        return personalActions.SetSettings({ value: res });
                    })
                );
            })
        );
    });

    SetSettings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(personalActions.SetSettings),
            tap((actions) => {
                if (actions.value.find((setting: SettingModel) => setting.key === SettingsKeyName.LAST_PPG_UPDATE)) {
                    this.store.dispatch(LastPPGUpdate({ value: actions.value.find((setting: SettingModel) => setting.key === SettingsKeyName.LAST_PPG_UPDATE)?.value! }))
                } else {
                    this.store.dispatch(LastPPGUpdate({ value: '' }))
                }
            }),
            exhaustMap((actions) => {
                return this.settingService.getUserInfo().pipe(
                    map((res) => {
                        return personalActions.SetInformationUser({
                            value: res,
                        });
                    })
                );
            })
        );
    });

    setMaiaApplicationPreferences$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(personalActions.SetMaiaApplicationPreferences),
            map(() => {
                return personalActions.SetMaiaApplicationPreferencesSuccess();
            })
        );
    });

    setSettings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                personalActions.SetMaiaApplicationPreferencesSuccess,
                personalActions.SetSourceManagement,
                personalActions.SetNotification,
                personalActions.SaveContactSettings
            ),
            withLatestFrom(
                this.store.select(userSelectors.selectCurrentUser),
                this.store.select(personalSelectors.getMaiaApplicationPreferences),
                this.store.select(chatSelectors.selectIsUknowChat),
                this.store.select(personalSelectors.getMaiaApplicationPreferences),
                this.store.select(personalSelectors.getSourceManagement),
                this.store.select(personalSelectors.getNotification),
                this.store.select(personalSelectors.getContactSettings)
            ),
            map(([action, currUser, appPreference, isUknowChat, maiaApplication, sourceManagement, notification, contactSettings]) => {
                if (!isUknowChat) {
                    let setting = {
                        key: SettingsKeyName.MAIA_APPLICATION,
                        user_id: currUser.oid,
                        id: appPreference.settId,
                        value: JSON.stringify(this.makeSettings(maiaApplication, sourceManagement, notification, contactSettings)),
                    };
                    if (!appPreference.settId) {
                        return personalActions.createSetting({ value: setting });
                    } else {
                        return personalActions.updateSetting({ value: setting });
                    }

                } else {
                    return personalActions.UpdateDone();
                }

            })
        );
    });

    createSetting$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(personalActions.createSetting),
            exhaustMap((action) => {
                return this.settingService.createSettings(action.value).pipe(
                    map(() => {
                        return personalActions.UpdateDone();
                    })
                );
            })
        );
    });

    updateSetting$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(personalActions.updateSetting),
            exhaustMap((action) => {
                return this.settingService.updateSetting(action.value).pipe(
                    map(() => {
                        return personalActions.UpdateDone();
                    })
                );
            })
        );
    });

    updateInformationUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(personalActions.UpdateInformationUser),
            exhaustMap((action) => {
                return this.settingService.updateUserInfo(action.value).pipe(
                    map(() => {
                        return personalActions.UpdateDone();
                    })
                );
            })
        );
    });

    updateEnabledCalendars$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(personalActions.UpdateEnabledCalendars),
            withLatestFrom(
                this.store.select(userSelectors.selectCurrentUser)
            ),
            exhaustMap(([action, currUser]) => {
                return this.settingService.updateGoogleCalendars(currUser.oid, action.value).pipe(
                    map(() => {
                        return personalActions.UpdateDone();
                    })
                );
            })
        );
    });

    updateTimezone$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(personalActions.UpdateTimezone),
            withLatestFrom(
                this.store.select(userSelectors.selectCurrentUser),
                this.store.select(personalSelectors.getGoogleTimezone)
            ),
            exhaustMap(([action, currUser, currTimezone]) => {
                let setting = {
                    key: SettingsKeyName.TIMEZONE,
                    user_id: currUser.oid,
                    id: currTimezone.settId,
                    value: action.value
                };
                return this.settingService.updateSetting(setting).pipe(
                    map(() => {
                        return personalActions.UpdateDone();
                    })
                );
            })
        );
    });

    makeSettings(
        maiaApplicationPreferences: MaiaApplicationPreferences,
        sourceManagement: SourceManagement,
        notification: SettingNotification | null,
        contactSettings: ContactsSettings
    ) {
        let tmpSettings = {
            sourceManagement: sourceManagement,
            notification: notification!,
            contactSettings: contactSettings!,
            ...maiaApplicationPreferences,
        };
        delete tmpSettings.settId;
        return tmpSettings;
    }

    constructor(
        private actions$: Actions,
        private settingService: SettingService,
        private store: Store
    ) { }
}
