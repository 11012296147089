import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { API_URL } from '@constants/api.constant';

@Injectable({
	providedIn: 'root',
})
export class SearchService {
	constructor(private http: HttpClient) { }
	searchResults(searchValue: string): Observable<any[]> {
		if (searchValue === '') {
			return of([]);
		}
		return this.http.post<any[]>(API_URL.search, {
			"text": searchValue
		}, { observe: 'body' });
	};



}
