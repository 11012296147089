import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '@constants/api.constant'
import { CalendarModel, CreateCalendarModel, GetEventsModel, SaveEventModel } from '@models/calendar.model';
import { CalendarDataModel } from '@models/chat.model';

@Injectable({
	providedIn: 'root',
})
export class MaiaCalendarService {
	constructor(private http: HttpClient) { }



	getCalendar() {
		return this.http.get<CalendarModel[]>(API_URL.readMaiaCalendar,
			{ responseType: 'json' }
		);
	}

	createCalendar(body: CreateCalendarModel) {
		return this.http.post<number>(API_URL.creaeteMaiaCalendar,
			body,
			{ responseType: 'json' }
		);
	}

	deleteCalendar(calendarId: number) {
		return this.http.post<any>(`${API_URL.deleteMaiaCalendar}/${calendarId}`,
			null,
			{ responseType: 'json' }
		);
	}

	getEvents(body: GetEventsModel) {
		return this.http.post<CalendarDataModel[]>(API_URL.searchEvents,
			body,
			{ responseType: 'json' }
		);
	}




	createMaiaEvent(body: SaveEventModel) {
		return this.http.post<number>(API_URL.createMaiaEvent, body, { responseType: 'json' })
	}

	deleteMaiaEvent(idEvent: number) {
		return this.http.post<any>(`${API_URL.deleteMaiaEvent}${idEvent}`, null, { responseType: 'json' })
	}


	updateMaiaEvent(body: SaveEventModel) {
		return this.http.post<number>(API_URL.updateMaiaEvent, body, { responseType: 'json' })
	}
}