import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AboutYouSectionComponent } from './about-you-section.component';

@NgModule({
  declarations: [AboutYouSectionComponent],
  exports: [AboutYouSectionComponent],
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule],
  providers: [],
})
export class AboutYouSectionModule {}
