import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { map, mergeMap, withLatestFrom } from 'rxjs';
import { CalendarEventModel, CalendarModel, CreateCalendarModel, SaveEventModel } from '@models/calendar.model';
import { CalendarDataModel } from '@models/chat.model';
import { MaiaCalendarService } from '@services/calendar.service';
import * as calendarActions from '@store/actions/calendar.action';
import * as calendarSelectors from '@store/selectors/calendar.selector';
import * as personalSettingsSelectors from '@store/selectors/personalSettings.selector';
import { TimeUtils } from '@utils/times';
import { GoogleCalendarModel } from '@models/google.model';




// const newColors = ['#AC725E', '#D06B64', '#F83A22', '#FA573C', '#FF7537', '#FFAD46', '#42D692', '#16A765', '#7BD148', '#B3DC6C', '#FBE983', '#FAD165', '#92E1C0', '#9FE1E7', '#9FC6E7', '#4986E7', '#9A9CFF', '#B99AFF', '#C2C2C2', '#CABDBF', '#CCA6AC', '#F691B2', '#CD74E6', '#A47AE2'];



@Injectable()
export class CalendarEffects {
	$googleCalendar = this.store.select(personalSettingsSelectors.getGoogleCalendar);
	$avaiableCalendar = this.store.select(calendarSelectors.selectAvaiableCalendar);


	getCalendars$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(calendarActions.GetCalendar),
			withLatestFrom(
				this.store.select(personalSettingsSelectors.getInformation)
			),
			mergeMap((data) => {
				return this.service.getCalendar().pipe(
					map(calendars => {
						if (calendars.length > 0) {
							return calendarActions.SetCalendar({ value: calendars })
						} else {
							let body: CreateCalendarModel = {
								user_id: data[1].id!,
								name: 'defaultCalendar',
								color: '#007579'
							}
							return calendarActions.CreateCalendar({ value: body })
						}
					})
				)
			})
		)
	});



	createCalendar$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(calendarActions.CreateCalendar),
			mergeMap((action) => {
				return this.service.createCalendar(action.value).pipe(
					map(() => {
						return calendarActions.GetCalendar()
					})
				)
			})
		)
	});








	getEvents$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(calendarActions.GetEvents),
			mergeMap((action) => {
				return this.service.getEvents(action.value).pipe(
					map(events => {
						return calendarActions.SetEvents({ value: this.generateEvents(events) });
					})
				)
			})
		)
	});



	generateEvents(events: CalendarDataModel[]): CalendarEventModel[] {
		const eventData: CalendarEventModel[] = []
		const colors = this.colorPalette();
		events.forEach((event: CalendarDataModel) => {
			eventData.push({
				id: event.id_event,
				title: event.title,
				start: new Date(event.start),
				end: new Date(event.end!),
				allDay: event.is_allday,
				classNames: [event.engine!],
				backgroundColor: colors[event.id_calendar].primary,
				borderColor: colors[event.id_calendar].primary,
				extendedProps: {
					...(event.description && { description: event.description }),
					engine: event.engine,
					dbId: event.id
				},
				editable: event.engine !== 'google'
			})
		})
		return eventData;
	}

	private colorPalette() {
		var colors: any = {}
		this.$googleCalendar.subscribe((res) => {
			if (res)
				res.map((gcm: GoogleCalendarModel) => {
					colors[gcm.id] = {
						primary: gcm.color
					}
				})
		});
		this.$avaiableCalendar.subscribe((res) => {
			if (res)
				res.map((gcm: CalendarModel) => {
					colors[gcm.id] = {
						primary: gcm.color
					}
				})
		})
		return colors
	}

	// private hexToRgb(hex: string) {
	// 	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	// 	return result ? {
	// 		r: parseInt(result[1], 16),
	// 		g: parseInt(result[2], 16),
	// 		b: parseInt(result[3], 16)
	// 	} : null;
	// }
	// private hexToRgb(hex: string) {
	// 	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	// 	return result ? {
	// 		r: parseInt(result[1], 16),
	// 		g: parseInt(result[2], 16),
	// 		b: parseInt(result[3], 16)
	// 	} : null;
	// }

	saveEvent$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(calendarActions.SaveEvent),
			withLatestFrom(
				this.store.select(calendarSelectors.selectCurrentCalendar),
				this.store.select(calendarSelectors.selectCurrentEvent),
				this.store.select(calendarSelectors.selectCurrentEvents)
			),
			mergeMap((data) => {
				this.store.dispatch(calendarActions.CalendarApiStatus({ value: true }));
				let tmpEvt: SaveEventModel;
				let date = TimeUtils.toIsoString(data[0].value.start, data[0].value.start_time);
				if (data[2]!) {
					tmpEvt = {
						...(data[0].value.id_db && { id: data[0].value.id_db }),
						id_event: data[2]!.id,
						id_user: data[1]!.user_id,
						id_calendar: data[1]!.id,
						engine: 'maia',
						start: TimeUtils.toIsoString(data[0].value.start, data[0].value.start_time),
						end: TimeUtils.toIsoString(data[0].value.end, data[0].value.end_time),
						title: data[0].value.title,
						is_allday: data[0].value.all_day,
						description: data[0].value.description
					}
				} else {
					tmpEvt = {
						id: data[0].value.id_db,
						id_event: data[0].value.id_event,
						id_user: data[1]!.user_id,
						id_calendar: data[1]!.id,
						engine: 'maia',
						start: TimeUtils.toIsoString(data[0].value.start, data[0].value.start_time),
						end: TimeUtils.toIsoString(data[0].value.end, data[0].value.end_time),
						title: data[0].value.title,
						is_allday: data[0].value.all_day,
						description: data[0].value.description
					}
				}
				let newEvent = this.generateEvents([tmpEvt] as CalendarDataModel[]);
				if (tmpEvt.id) {
					return this.service.updateMaiaEvent(tmpEvt).pipe(
						map((res) => {
							let dd = [...data[3]!];
							let index = data[3]!.findIndex((evt) => evt.id === data[0].value.id_event);
							dd[index] = newEvent[0]
							return calendarActions.SetEvents({ value: [...dd] });
						})
					)
				} else {
					return this.service.createMaiaEvent(tmpEvt).pipe(
						map((res) => {
							newEvent[0].extendedProps['dbId'] = res
							return calendarActions.SetEvents({ value: [...data[3]!, ...newEvent] })
						})
					)
				}
			})
		)
	});



	deleteEvent$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(calendarActions.DeleteEvent),
			withLatestFrom(
				this.store.select(calendarSelectors.selectCurrentEvents)
			),
			mergeMap((data) => {
				this.store.dispatch(calendarActions.CalendarApiStatus({ value: true }));
				let currentEvents = data[1].filter((evt: CalendarEventModel) => evt.extendedProps.dbId !== data[0].value)!
				return this.service.deleteMaiaEvent(data[0].value).pipe(
					map(() => calendarActions.SetEvents({ value: [...currentEvents!] }))
				)
			})
		)
	})




	constructor(
		private actions$: Actions,
		private store: Store,
		private service: MaiaCalendarService,
	) { }
}