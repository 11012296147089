import { HttpBackend, HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './components/auth/auth.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthService } from '@services/auth.service';
import { OnboardComponent } from './components/onboard/onboard.component';
import { DefaultValueAccessor } from '@angular/forms';
import { StepperModule } from './components/stepper/stepper.module';
import { NavigationService } from '@services/navigation.service';
import { MobileViewService } from '@services/isMobileView.service';
import { PasswordSectionModule } from './components/sign-up/password-section/password-section.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';
import { GOOGLE } from '@constants/google.constant';
import { reducers } from '@store/index';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './store/effects/user.effect';
import { ChatEffects } from './store/effects/chat.effect';
import { ButtonModule } from './components/button/button.module';
import { PreviousUrlService } from '@services/previousUrl.service';
import { PersonalSettingsEffects } from './store/effects/personalSettings.effect';
import { ContactsEffects } from './store/effects/contacts.effect';
import { SearchEffects } from './store/effects/search.effect';
import { PersonalGraphEffects } from './store/effects/personalGraph.effect';
import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { CookiebotConfig } from './cookiebot.config';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FullscreenService } from '@services/fullscreen.service';
import { UpdateAppComponent } from './components/update-app/update-app.component';
import { CalendarEffects } from './store/effects/calendar.effect';
import { isString } from '@utils/genericUtils';
import { UdriveEffects } from './store/effects/udrive.effect';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { UknowEffects } from './store/effects/uknow.effect';
import { UnderMaintainanceComponent } from './components/under-maintainance/under-maintainance.component';
import { ForcedUpdateComponent } from './components/forced-update/forced-update.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import { NotificationSnackbarComponent } from './components/notification-snackbar/notification-snackbar.component';
registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeIt);


export const translatorConf = {
	loader: {
		provide: TranslateLoader,
		useFactory: HttpLoaderFactory,
		deps: [HttpBackend],
	},
	isolate: false
};

export const OtherOptions: MatTooltipDefaultOptions = {
	showDelay: 0,
	hideDelay: 0,
	touchGestures: 'auto',
	touchendHideDelay: 0,
	disableTooltipInteractivity: true,
}




@NgModule({
	declarations: [
		AppComponent,
		AuthComponent,
		OnboardComponent,
		WelcomeComponent,
		UpdateAppComponent,
		UnderMaintainanceComponent,
		ForcedUpdateComponent,
  NotificationSnackbarComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		PasswordSectionModule,
		ButtonModule,
		TranslateModule.forRoot(translatorConf),
		StepperModule,
		StoreModule.forRoot(reducers),
		NgxCookiebotModule.forRoot(CookiebotConfig),
		NgxGoogleAnalyticsModule.forRoot(GOOGLE.googleAnaliticsCode),
		NgxGoogleAnalyticsRouterModule,
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: environment.production,
		}),
		EffectsModule.forRoot([
			UserEffects,
			ChatEffects,
			PersonalSettingsEffects,
			ContactsEffects,
			SearchEffects,
			PersonalGraphEffects,
			CalendarEffects,
			UdriveEffects,
			UknowEffects
		]),
		LottieModule.forRoot({ player: playerFactory }),
		LottieCacheModule.forRoot(),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production || environment.test,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		})
	],
	providers: [
		DatePipe,
		AuthService,
		NavigationService,
		MobileViewService,
		PreviousUrlService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		FullscreenService,
		{
			provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
			useValue: OtherOptions
		},
		{ provide: LOCALE_ID, useValue: 'it-IT' }, TranslateService
	],
	bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(httpBackend: HttpBackend) {
	return new TranslateHttpLoader(
		new HttpClient(httpBackend),
		'./assets/i18n/',
		'.json'
	);
}

const original = DefaultValueAccessor.prototype.registerOnChange;
DefaultValueAccessor.prototype.registerOnChange = function (fn) {
	return original.call(this, value => {
		const trimmed = isString(value) ? value.trim() : value;
		return fn(trimmed);
	})
};

export function playerFactory(): any {
	return import('lottie-web');
}


