import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AppRoutes } from '@utils/routes';
import { GenericUtils } from '@utils/genericUtils';
import { Regex } from '@utils/regex';
import { Store } from '@ngrx/store';
import { ButtonType, ErrorApi } from '@models/generic.model';
import { LoginModel } from '@models/login.model';
import { LogInSuccess } from '@store/actions/user.action';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'maia-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {
	active: number;
	signInForm: FormGroup;
	recoverForm: FormGroup;
	recovery: boolean = false;
	submit: boolean = false;
	hidePass: boolean = true;
	hidePass2: boolean = true;
	//errorMessage!: string;
	codeSend: boolean = false;
	postReset: boolean = false;
	btnType: typeof ButtonType = ButtonType;
	private destroyed$: Subject<boolean> = new Subject<boolean>();
	constructor(
		private fb: FormBuilder,
		private authService: AuthService,
		private router: Router,
		private store: Store
	) {
		this.active = 1;
		this.signInForm = this.fb.group({
			email: [null, Validators.required],
			password: [null, Validators.required],
		});
		this.recoverForm = this.fb.group({
			emailRecovery: [null, Validators.required],
		});
	}

	ngOnInit(): void {
		this.signInForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
			this.signInForm.controls['email'].setErrors(null);
			this.signInForm.controls['password'].setErrors(null);
		})
	}

	onSignInSubmit() {
		const val = this.signInForm.value;
		this.submit = true;
		if (val.email && val.password) {
			this.authService.signIn(val.email, val.password).subscribe({
				next: (response: LoginModel) => {
					this.authService.setToken(response);
					this.store.dispatch(
						LogInSuccess({ userDetail: JSON.parse(localStorage.getItem('maia_user_detail')!) })
					);
				},
				error: (res: HttpErrorResponse) => {
					this.manageError(res.error.errorCode);
					this.submit = false;
				}
			})
		}
	}

	showPass() {
		this.hidePass = !this.hidePass;
	}
	showPass2() {
		this.hidePass2 = !this.hidePass2;
	}

	switchTo() {
		this.recovery = !this.recovery;
		this.signInForm.reset();
		this.recoverForm.reset();
	}

	sendCode() {
		this.submit = true;
		this.authService
			.getRecoverPasswordCode(this.recoverForm.controls['emailRecovery'].value)
			.subscribe({
				next: () => {
					this.addControls();
					this.submit = false;
					this.codeSend = true;
				},
				error: (res: HttpErrorResponse) => {
					this.manageError(res.error.errorCode);
					this.submit = false;
				},
			});
	}

	addControls() {
		this.recoverForm.get('emailRecovery')?.valid;
		this.recoverForm.addControl(
			'totp',
			this.fb.control(
				null,
				Validators.compose([Validators.required, Validators.pattern('[0-9]+'), Validators.minLength(6)])
			)
		);
		this.recoverForm.addControl(
			'password',
			this.fb.control(
				null,
				Validators.compose([
					Validators.required,
					GenericUtils.patternValidator(Regex.PASSWORD_NUMBER_VALIDATOR, {
						numberMissing: true,
					}),
					GenericUtils.patternValidator(Regex.PASSWORD_UPPERCASE_VALIDATOR, {
						upperCaseMissing: true,
					}),
					GenericUtils.patternValidator(Regex.PASSWORD_LOWERCASE_VALIDATOR, {
						lowerCaseMissing: true,
					}),
					GenericUtils.patternValidator(
						Regex.PASSWORD_SPECIAL_CHARACTER_VALIDATOR,
						{ specialCharacterMissing: true }
					),
					Validators.minLength(10),
				])
			)
		);
		this.recoverForm.addControl(
			'confirm_password',
			this.fb.control(
				null,
				Validators.compose([
					Validators.required,
					GenericUtils.matchOtherValidator('password'),
				])
			)
		);
	}
	onRecoverSubmit() {
		this.submit = true;
		let obj = {
			email: this.recoverForm.value.emailRecovery,
			totp: this.recoverForm.value.totp,
			password: window.btoa(this.recoverForm.value.password),
		};
		this.authService.resetPassword(obj).subscribe({
			next: () => {
				this.postReset = true;
				this.submit = false;
			},
			error: (res: HttpErrorResponse) => {
				this.manageError(res.error.errorCode);
				this.submit = false;
			},
		});
	}

	postResetLogin() {
		this.submit = true;
		this.authService
			.signIn(
				this.recoverForm.value.emailRecovery,
				this.recoverForm.value.password
			)
			.subscribe({
				next: (res) => {
					this.submit = false;
					this.router.navigate(['/']);
				},
				error: (res: ErrorEvent) => {
					this.manageError(res.error.errorCode);
					this.submit = false;
				},
			});
	}



	private manageError(res: string) {
		switch (res) {
			case ErrorApi.INV_PASS_MAIL:
				this.signInForm.controls['email'].setErrors({ invalidUser: true });
				this.signInForm.controls['password'].setErrors({ invalidUser: true });
				break;
			case ErrorApi.NOT_EXI_USR:
				this.recoverForm.controls['emailRecovery'].setErrors({ invalidUser: true });
				break;
			case ErrorApi.ACC_LOCK:
				this.signInForm.setErrors({ accLock: true });
				break;
			case ErrorApi.INV_TOTP:
				this.recoverForm.controls['totp'].setErrors({ invalidTotp: true });
				break
		}
	}

	goToRegister() {
		this.router.navigate([`/${AppRoutes.SIGN_UP}`]);
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
}
