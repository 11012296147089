<div class="top-4 left-5 absolute md:block hidden">
	<img loading="lazy" src="assets/images/maia_logo_transparent.png" alt="maia logo desktop view" />
</div>
<div class="sm:w-2/3 lg:w-1/2 w-full h-full sm:max-h-[90vh] sm:rounded-xl bg-white" [ngStyle]="isMobileView ? {'height': innerHeight+'px'} : null">
	<div class="w-full py-4 h-full flex flex-col items-center justify-evenly">
		<div class="flex items-center justify-center relative my-3 w-full px-3 md:px-16">
			<i *ngIf="!done" class="icon-back text-3xl text-green-light-700 absolute left-3 cursor-pointer" (click)="goBack()"></i>
			<span class="font-bold">{{'SIGN_UP.title' | translate}}</span>
		</div>
		<div class="w-full grow overflow-y-auto custom-scroll">
			<div [ngSwitch]="currentSection" class="w-full h-full flex grow overflow-y-auto overflow-x-hidden custom-scroll px-3 md:px-16">
				<div *ngSwitchCase="section.MAIL" [@slideInFromLeftAnimation]="isAnimated(prevSection === section.CODE)" class="w-full">
					<div class="md:max-w-screen-md md:mx-auto flex flex-col text-center">
						<span class="text-green-dark-900 text-3xl py-7">{{ "SIGN_UP.verify_email_title" | translate }}</span>
						<span class="text-md md:text-lg">{{ "SIGN_UP.verify_email_first_subtitle" | translate }}</span>
						<span class="text-md md:text-lg pt-3">{{ "SIGN_UP.verify_email_second_subtitle" | translate }}</span>
					</div>
					<form class="pt-10" [formGroup]="verifyEmailForm">
						<div class="flex flex-col mb-5">
							<label for="verify_email" class="text-green-dark-900 text-xs pl-2 mb-1">{{ "SIGN_UP.email" | translate }} *</label>
							<div class="flex items-center bg-grey-light min-h-[52px] pl-2 border-b border-green-light-900 pr-2 focus-within:border-green-light-600 transition-colors" [ngClass]="{'border-red-700 pr-2 focus-within:border-red-500': verifyEmailForm.get('verify_email')!.hasError('userExist') || verifyEmailForm.get('verify_email')!.touched && !verifyEmailForm.get('verify_email')!.valid}">
								<input type="email" class="outline-none bg-transparent flex-1" id="verify_email" formControlName="verify_email" placeholder="name@example.com" email>
							</div>
							<div *ngIf="verifyEmailForm.get('verify_email')!.hasError('userExist')" class="flex items-center text-xs p-1 text-red-700 font-semibold">
								{{ "ERRORS.exi_usr" | translate }}
							</div>
							<div *ngIf="verifyEmailForm.get('verify_email')!.touched && !verifyEmailForm.get('verify_email')!.valid && !verifyEmailForm.get('verify_email')!.hasError('userExist')" class="flex items-center text-xs p-1 text-red-700 font-semibold">
								{{ "AUTH.insert_valid_email" | translate }}
							</div>
						</div>
						<div class="flex items-start mb-2">
							<input class="w-7 h-7 shrink-0" type="checkbox" formControlName="privacy" id="privacy" [value]="verifyEmailForm.get('privacy')?.value" />
							<label for="privacy" class="text-sm font-medium pl-2 cursor-pointer" [innerHTML]="('SIGN_UP.privacy_policy' | translate) + '*'"></label>
						</div>
						<div class="flex items-start mb-2">
							<input class="w-7 h-7 shrink-0" type="checkbox" formControlName="personalData" id="personalData" [value]="verifyEmailForm.get('personalData')?.value" (change)="onPersonalDataChange($event)" />
							<label for="personalData" class="text-sm font-medium pl-2 cursor-pointer" [innerHTML]="('SIGN_UP.personal_data' | translate)"></label>
						</div>
						<div class="flex items-start mb-2 pl-7">
							<input class="w-5 h-5 shrink-0" type="checkbox" formControlName="localizationPolicy" id="localizationPolicy" [value]="verifyEmailForm.get('localizationPolicy')?.value" (change)="onLocChange($event)" />
							<label for="localizationPolicy" class="text-xs font-medium pl-2 cursor-pointer" [innerHTML]="'SIGN_UP.loc_consens' | translate"></label>
						</div>
						<div class="flex items-start mb-2 pl-7">
							<input class="w-5 h-5 shrink-0" type="checkbox" formControlName="mailCalPolicy" id="mailCalPolicy" [value]="verifyEmailForm.get('mailCalPolicy')?.value" (change)="onSyncChange($event)" />
							<label for="mailCalPolicy" class="text-xs font-medium pl-2 cursor-pointer" [innerHTML]="'SIGN_UP.sync_consens' | translate"></label>
						</div>
						<div class="flex items-start mb-2 pl-7">
							<input class="w-5 h-5 shrink-0" type="checkbox" formControlName="microphonePolicy" id="microphonePolicy" [value]="verifyEmailForm.get('microphonePolicy')?.value" (change)="onMicChange($event)" />
							<label for="microphonePolicy" class="text-xs font-medium pl-2 cursor-pointer" [innerHTML]="'SIGN_UP.mic_consense' | translate"></label>
						</div>
						<div class="flex items-start mb-2 pl-7">
							<input class="w-5 h-5 shrink-0" type="checkbox" formControlName="custMarkPolicy" id="custMarkPolicy" [value]="verifyEmailForm.get('custMarkPolicy')?.value" (change)="onMktChange($event)" />
							<label for="custMarkPolicy" class="text-xs font-medium pl-2 cursor-pointer" [innerHTML]="'SIGN_UP.mkt_consens' | translate"></label>
						</div>
						<div class="flex items-start mb-5 pl-7">
							<input class="w-5 h-5 shrink-0" type="checkbox" formControlName="dataThirdPartPolicy" id="dataThirdPartPolicy" [value]="verifyEmailForm.get('dataThirdPartPolicy')?.value" (change)="onAssChange($event)" />
							<label for="dataThirdPartPolicy" class="text-xs font-medium pl-2 cursor-pointer" [innerHTML]="'SIGN_UP.ces_consens' | translate"></label>
						</div>
						<div class="flex items-start mb-5">
							<input class="w-7 h-7 shrink-0" type="checkbox" formControlName="tos" id="tos" [value]="verifyEmailForm.get('tos')?.value" (change)="onTosChange($event)" />
							<label for="tos" class="text-sm font-medium pl-2 cursor-pointer" [innerHTML]="('SIGN_UP.tos' | translate) + '*'"></label>
						</div>
						<div class="flex items-start">
							<input class="w-7 h-7 shrink-0" type="checkbox" formControlName="clauses" id="clauses" [value]="verifyEmailForm.get('clauses')?.value" (change)="onClausesChange($event)" />
							<label for="clauses" class="text-sm font-medium pl-2 cursor-pointer" [innerHTML]="('SIGN_UP.clauses' | translate) + '*'"></label>
						</div>
					</form>
				</div>
				<div *ngSwitchCase="section.CODE" [@slideInFromRightAnimation]="isAnimated(prevSection === section.MAIL)" [@slideInFromLeftAnimation]="isAnimated(prevSection === section.PASSWORD)" class="w-full">
					<div class="md:max-w-screen-md md:mx-auto flex flex-col text-center">
						<span class="text-green-dark-900 text-3xl py-7">{{ "SIGN_UP.verify_email_title" | translate }}</span>
						<span class="text-lg" *ngIf="verifyEmailForm.get('verify_email')?.value" [innerHtml]="'SIGN_UP.verify_code_first_subtitle' | translate:{email: verifyEmailForm.get('verify_email')?.value}"></span>
						<span class="text-lg pt-3">{{ "SIGN_UP.verify_code_second_subtitle" | translate }}</span>
					</div>
					<form class="pt-10 md:max-w-md md:mx-auto" [formGroup]="verifyCodeForm">
						<div class="flex flex-col code-input">
							<label for="code" class="text-green-dark-900 text-xs pl-2">{{ "SIGN_UP.verify_code" | translate }} *</label>
							<div class="flex items-center bg-grey-light min-h-[52px] pl-2 border-b border-green-light-900 pr-2 focus-within:border-green-light-600 transition-colors" [ngClass]="{'border-red-700 pr-2 focus-within:border-red-500': verifyCodeForm.get('code')!.hasError('invalidTotp') || verifyCodeForm.get('code')!.hasError('pattern') || verifyCodeForm.get('code')!.hasError('minlength')}">
								<input type="text" class="outline-none bg-transparent flex-1" pattern="[0-9]*" id="code" formControlName="code">
							</div>
						</div>
						<div *ngIf="verifyCodeForm.get('code')!.hasError('pattern')" class="flex items-center text-xs p-1 text-red-700 font-semibold">
							{{"ERRORS.only_numbers" | translate }}
						</div>
						<div *ngIf="verifyCodeForm.get('code')!.hasError('minlength')" class="flex items-center text-xs p-1 text-red-700 font-semibold">
							{{"ERRORS.character_lenght" | translate:{characterNumber: 6} }}
						</div>
						<div *ngIf="verifyCodeForm.get('code')!.hasError('invalidTotp')" class="flex items-center text-xs p-1 text-red-700 font-semibold">
							{{ "ERRORS.inv_totp" | translate }}
						</div>
					</form>
				</div>
				<div *ngSwitchDefault [@slideInFromRightAnimation]="isAnimated(prevSection === section.CODE)" [@slideInFromLeftAnimation]="isAnimated(prevSection === section.PASSWORD)" class="w-full">
					<maia-stepper (stepperStatus)="getStepperStatus($event)" [next]="next" [prev]="prev" [isDisabled]="isMobileView || done ? isNextDisabled() : isCreateAccountDisabled()" [currIndex]="currIndex" *ngIf="isMobileView" [autoHeight]="false" [height]="innerHeight">
						<ng-template maia-stepper-section>
							<div class="pb-5">
								<maia-password-section [form]="addPasswordForm" (onSubmit)="addPassword()"></maia-password-section>
							</div>
						</ng-template>
						<ng-template maia-stepper-section>
							<div class="pb-5">
								<maia-generic-information-section [form]="genericInformationForm" (onSubmit)="changeSection(section.INTERESTS)"></maia-generic-information-section>
							</div>
						</ng-template>
						<ng-template maia-stepper-section>
							<div class="pb-5">
								<maia-interests-section [form]="interestsForm" (onSubmit)="changeSection(section.SOMETHING_ABOUT_YOU)"></maia-interests-section>
							</div>
						</ng-template>
						<ng-template maia-stepper-section>
							<div class="pb-5">
								<maia-about-you-section [form]="aboutYouForm" (onSubmit)="changeSection(section.END_SECTION)"></maia-about-you-section>
							</div>
						</ng-template>
					</maia-stepper>
					<div class="max-w-screen-lg mx-auto" *ngIf="!isMobileView && currentSection !== section.END_SECTION">
						<maia-password-section [form]="addPasswordForm"></maia-password-section>
						<maia-generic-information-section [form]="genericInformationForm"></maia-generic-information-section>
						<maia-interests-section [form]="interestsForm"></maia-interests-section>
						<maia-about-you-section [form]="aboutYouForm"></maia-about-you-section>
					</div>
				</div>
			</div>
		</div>
		<div [ngSwitch]="currentSection" class="w-full shrink-0 p-3 sm:overflow-hidden">
			<div class="md:max-w-md md:mx-auto" *ngSwitchCase="section.MAIL" [@slideInFromLeftAnimation]="isAnimated(prevSection === section.CODE)">
				<div class="mb-5">
					<maia-button [type]="'button'" [btnType]="btnType.PRIMARY" [label]="'SIGN_UP.send_email' | translate" [isDisabled]="!verifyEmailForm.valid" (onClick)="verifyEmail()" [spinnerStatus]="verifingEmail" class="mb-5"></maia-button>
				</div>
				<maia-button [type]="'button'" [btnType]="btnType.PRIMARY_EMPTY" [label]="'SIGN_UP.already_have_code' | translate" [isDisabled]="!verifyEmailForm.valid" (onClick)="changeSection(section.CODE)"></maia-button>
			</div>
			<div class="md:max-w-md md:mx-auto" *ngSwitchCase="section.CODE" [@slideInFromRightAnimation]="isAnimated(prevSection === section.MAIL)" [@slideInFromLeftAnimation]="isAnimated(prevSection === section.PASSWORD)">
				<div class="mb-5">
					<maia-button [type]="'button'" [btnType]="btnType.PRIMARY" [label]="'SIGN_UP.confirm_code' | translate" [isDisabled]="!verifyCodeForm.valid" (onClick)="changeSection(section.PASSWORD)"></maia-button>
				</div>
				<maia-button [type]="'button'" [btnType]="btnType.PRIMARY_EMPTY" [label]="'SIGN_UP.resend_email' | translate" (onClick)="verifyEmail()" [spinnerStatus]="verifingEmail"></maia-button>
			</div>
			<div class="md:max-w-md md:mx-auto" *ngSwitchDefault [@slideInFromRightAnimation]="isAnimated(prevSection === section.CODE)" [@slideInFromLeftAnimation]="isAnimated(prevSection === section.PASSWORD)">
				<maia-button [type]="'submit'" [btnType]="!done && isMobileView ? btnType.PRIMARY_EMPTY : btnType.PRIMARY" [isDisabled]="isMobileView || done ? isNextDisabled() : isCreateAccountDisabled()" [label]="(!done ? (isMobileView ? 'SIGN_UP.next' : 'SIGN_UP.complete_registration') : 'SIGN_UP.lets_chat') | translate" (onClick)="isMobileView ? (!done) ? onNext() : createUser() : createUser()" [spinnerStatus]="creatingUser"></maia-button>
			</div>
		</div>
	</div>
</div>