<div class="top-4 left-5 absolute md:block hidden">
	<img loading="lazy" src="assets/images/maia_logo_transparent.png" alt="maia logo desktop view" />
</div>
<div class="sm:w-2/3 lg:w-1/2 w-full h-full sm:max-h-[90vh] sm:rounded-xl bg-white">
	<div *ngIf="!recovery" class="w-full py-4 px-3 md:px-16 h-full flex flex-col items-center justify-evenly">
		<div class="flex flex-col text-center">
			<span class="text-green-dark-900 text-3xl">{{ "AUTH.title" | translate }}</span>
		</div>
		<div class="w-full pt-3 pb-4 max-w-[300px]">
			<a href="https://mymaia.ai/registrazione-utente/" class="bg-green-light cursor-pointer font-bold px-3 py-5 rounded-lg text-white text-xl w-full">{{'AUTH.register_account' | translate}}</a>
			<!-- <maia-button [type]="'button'" customClass="py-5 text-xl" [btnType]="btnType.PRIMARY" [label]="'AUTH.register_account' | translate" (onClick)="goToRegister()"></maia-button> -->
		</div>
		<form [formGroup]="signInForm" (ngSubmit)="onSignInSubmit()" class="block w-full border-t">
			<div class="flex flex-col text-center py-2">
				<span class="text-md sm:text-lg pt-3" [innerHTML]="'AUTH.subtitle' | translate"></span>
			</div>
			<fieldset>
				<div class="flex flex-col">
					<label for="email" class="text-green-dark-900 text-xs pl-2">{{ "AUTH.email_address" | translate }}*</label>
					<div class="flex items-center bg-grey-light min-h-[52px] pl-2 border-b border-green-light-900 pr-2 focus-within:border-green-light-600 transition-colors" [ngClass]="{'border-red-700 pr-2 focus-within:border-red-500':signInForm.get('email')!.hasError('invalidUser') || (signInForm.get('email')!.touched && !signInForm.get('email')!.valid)}">
						<input type="email" class="outline-none bg-transparent flex-1" id="email" formControlName="email" placeholder="name@example.com" email>
					</div>
				</div>
				<div *ngIf="signInForm.get('email')!.touched && !signInForm.get('email')!.valid && !signInForm.get('email')!.hasError('invalidUser')" class="flex items-center text-xs p-1 text-red-700 font-semibold">
					{{ "AUTH.insert_valid_email" | translate }}
				</div>
				<div class="flex flex-col pt-5 relative">
					<label for="password" class="text-green-dark-900 text-xs pl-2">{{ "AUTH.password" | translate }}</label>
					<div class="flex items-center bg-grey-light min-h-[52px] pl-2 border-b border-green-dark-900 pr-2 focus-within:border-green-light-600 transition-colors" [ngClass]="{'border-red-700 pr-2 focus-within:border-red-500':signInForm.get('email')!.hasError('invalidUser')}">
						<input [type]="hidePass?'password':'text'" class="outline-none bg-transparent flex-1" id="password" formControlName="password" placeholder="{{ 'AUTH.password' | translate }}" autocomplete="off">
						<button type="button" class="flex items-center justify-center cursor-pointer" (click)="showPass()">
							<i class="icon-visibility text-3xl text-green-light-700" *ngIf="hidePass"></i>
							<i class="icon-visibility_off text-3xl text-green-light-700" *ngIf="!hidePass"></i>
						</button>
					</div>
				</div>
				<div *ngIf="signInForm.get('email')!.hasError('invalidUser')" class="flex items-center text-xs p-1 text-red-700 font-semibold">
					{{ "ERRORS.inv_pass_mail" | translate }}
				</div>
				<div *ngIf="signInForm.hasError('accLock')" class="flex items-center text-xs p-1 text-red-700 font-semibold">
					{{ "ERRORS.acc_lock" | translate }}
				</div>
				<div class="pt-5 max-w-[300px] mx-auto">
					<maia-button [type]="'submit'" [btnType]="btnType.PRIMARY" [label]="'AUTH.login' | translate" [isDisabled]="!signInForm.valid" [spinnerStatus]="submit"></maia-button>
				</div>
				<div class="flex items-center justify-center pt-5">
					<maia-button [type]="'button'" [btnType]="btnType.TEXT" [label]="'AUTH.forgot_password' | translate" (onClick)="switchTo()"></maia-button>
				</div>
			</fieldset>
		</form>
	</div>
	<div *ngIf="recovery" class="w-full py-4 px-3 md:px-16 h-full flex flex-col items-center justify-start">
		<div class="flex items-center justify-center relative my-3 w-full">
			<i class="icon-back text-3xl text-green-light-700 absolute left-0 cursor-pointer" (click)="switchTo()"></i>
			<span class="font-bold">{{'AUTH.reset_password' | translate}}</span>
		</div>
		<div class="w-full flex grow flex-col justify-center">
			<div class="flex" *ngIf="!postReset">
				<button type="button" (click)="recovery = !recovery">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="recovery-back-icon">
						<path d="m32.75 44-20-20 20-20 2.8 2.85L18.4 24l17.15 17.15Z" />
					</svg>
				</button>
			</div>
			<div class="text-lg py-5" *ngIf="!codeSend && !postReset">{{ "AUTH.add_registered_email" | translate }}
			</div>
			<form [formGroup]="recoverForm" (ngSubmit)="onRecoverSubmit()">
				<fieldset>
					<div *ngIf="!postReset && !codeSend" class="flex flex-col">
						<label for="emailRecovery" *ngIf="!codeSend || !postReset" class="text-green-dark-900 text-xs pl-2 mb-1">{{ "AUTH.email_address" | translate }} *</label>
						<div class="flex items-center bg-grey-light min-h-[52px] pl-2 border-b border-green-light-900 pr-2 focus-within:border-green-light-600 transition-colors" [ngClass]="{'border-red-700 pr-2 focus-within:border-red-500':recoverForm.get('emailRecovery')!.hasError('invalidUser') || (recoverForm.get('emailRecovery')!.touched && !recoverForm.get('emailRecovery')!.valid)}">
							<input type="email" class="outline-none bg-transparent flex-1" id="emailRecovery" formControlName="emailRecovery" placeholder="name@example.com" email [readOnly]="codeSend">
						</div>
					</div>
					<div *ngIf="recoverForm.get('emailRecovery')!.hasError('invalidUser')" class="flex items-center text-xs p-1 text-red-700 font-semibold">
						{{ "ERRORS.not_exi_usr" | translate }}
					</div>
					<div *ngIf="recoverForm.get('emailRecovery')!.touched && !recoverForm.get('emailRecovery')!.valid && !recoverForm.get('emailRecovery')!.hasError('invalidUser')" class="flex items-center text-xs p-1 text-red-700 font-semibold">
						{{"AUTH.insert_valid_email" | translate }}
					</div>
					<div *ngIf="codeSend && !postReset">
						<div class="pb-5"> <span class="text-lg">{{"AUTH.add_recived_code" | translate }}</span>
							<div class="flex flex-col pt-5">
								<label for="totp" class="text-green-dark-900 text-xs pl-2">{{"AUTH.recived_code" | translate }}</label>
								<div class="flex items-center bg-grey-light min-h-[52px] pl-2 border-b border-green-light-900 pr-2 focus-within:border-green-light-600 transition-colors" [ngClass]="{'border-red-700 pr-2 focus-within:border-red-500': recoverForm.get('totp')!.hasError('pattern') || recoverForm.get('totp')!.hasError('minlength') || recoverForm.get('totp')!.hasError('invalidTotp')}">
									<input type="text" id="totp" class="outline-none bg-transparent flex-1" formControlName="totp" placeholder="{{'AUTH.recived_code' | translate }}">
								</div>
							</div>
							<div *ngIf="recoverForm.get('totp')!.hasError('pattern')" class="flex items-center text-xs p-1 text-red-700 font-semibold">
								{{"ERRORS.only_numbers" | translate }}
							</div>
							<div *ngIf="recoverForm.get('totp')!.hasError('minlength')" class="flex items-center text-xs p-1 text-red-700 font-semibold">
								{{"ERRORS.character_lenght" | translate:{characterNumber: 6} }}
							</div>
							<div *ngIf="recoverForm.get('totp')!.hasError('invalidTotp')" class="flex items-center text-xs p-1 text-red-700 font-semibold">
								{{"ERRORS.inv_totp" | translate }}
							</div>
						</div>
						<maia-password-section [form]="recoverForm" [hideTitleSection]="true"></maia-password-section>
					</div>
				</fieldset>
				<span class="text-green" *ngIf="postReset">{{ "AUTH.password_changed_successfully" | translate }}</span>
				<div class="mt-10">
					<maia-button *ngIf="!codeSend && !postReset" [type]="'button'" [btnType]="btnType.PRIMARY" [label]="'AUTH.get_code' | translate" (onClick)="sendCode()" [isDisabled]="!recoverForm.valid" [spinnerStatus]="submit"></maia-button>
					<maia-button *ngIf="codeSend && !postReset" [type]="'submit'" [btnType]="btnType.PRIMARY" [label]="'AUTH.change_password' | translate" [isDisabled]="!recoverForm.valid" [spinnerStatus]="submit"></maia-button>
					<div>
						<maia-button *ngIf="postReset" [type]="'button'" [btnType]="btnType.PRIMARY" [label]="'AUTH.login' | translate" (onClick)="postResetLogin()" [spinnerStatus]="submit"></maia-button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>