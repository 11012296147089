import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { AppRoutes } from '@utils/routes';
import { ButtonType } from '@models/generic.model';
import { slideInFromTopAnimation } from '@utils/animations';
import { GenericUtils } from '@utils/genericUtils';
import { MobileViewService } from '@services/isMobileView.service';
import { NgxCookiebotService } from '@halloverden/ngx-cookiebot';
import { AnimationOptions } from 'ngx-lottie';



@Component({
    selector: 'maia-onboard',
    templateUrl: './onboard.component.html',
    styleUrls: ['./onboard.component.scss'],
    animations: [slideInFromTopAnimation],
})
export class OnboardComponent implements OnInit, AfterViewInit, OnDestroy {
    public isAnimated = GenericUtils.conditionalAnimationDuration;
    @ViewChild('sliderCont', { static: true }) sliderCont!: ElementRef;
    next: Subject<void> = new Subject<void>();
    prev: Subject<void> = new Subject<void>();
    btnType: typeof ButtonType = ButtonType;
    isMobileView: boolean = false;
    done: boolean = false;
    currIndex: number = 0;
    sliderWidth: number = 0;
    private destroyed$: Subject<boolean> = new Subject<boolean>();
    options: AnimationOptions = {
        path: '/assets/images/onboarding_m.json',
        renderer: 'svg',
        loop: false,
        autoplay: false
    };
    anim: any;
    constructor(
        private _cookieBotService: NgxCookiebotService,
        private router: Router,
        private mobileViewService: MobileViewService
    ) { }

    ngOnInit(): void {
        this.mobileViewService.isMobile.pipe(takeUntil(this.destroyed$)).subscribe({
            next: (isMobileView) => (this.isMobileView = isMobileView),
        });
    }

    ngAfterViewInit(): void {
        this._cookieBotService.onServiceReady$.pipe(
            takeUntil(this.destroyed$),
            filter((ready: boolean) => {
                if (this._cookieBotService.cookiebot && this._cookieBotService.cookiebot.consent.stamp === '0') document.querySelector('maia-onboard')?.setAttribute('style', 'opacity:.1;pointer-events:none')
                return ready;
            })
        ).subscribe(() => {
            this._cookieBotService.onAccept$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
                document.querySelector('maia-onboard')?.removeAttribute('style')
            });
        });
    }

    getStepperStatus(event: { index: number; done: boolean }) {
        this.done = event.done;
        this.currIndex = event.index;
    }



    onNext() {
        if (!this.done) {
            this.next.next();
        } else {
            localStorage.setItem('maia_boarded', 'true');
            this.router.navigate([AppRoutes.BASE]);
        }
    }


    getStepperAnimationControl(event: { index: number; direction: string }) {
        if (event.direction === 'next') {
            switch (event.index) {
                case 0:
                    this.anim.playSegments([0, 20], true)
                    break
                case 1:
                    this.anim.playSegments([20, 55], true)
                    break
                case 2:
                    this.anim.playSegments([55, 105], true)
                    break
                case 3:
                    this.anim.playSegments([105, 175], true)
            }
        } else {
            switch (event.index) {
                case 0:
                    this.anim.playSegments([55, 20], true)
                    break
                case 1:
                    this.anim.playSegments([105, 55], true)
                    break
                case 2:
                    this.anim.playSegments([175, 105], true)
            }
        }
    }


    onPrev() {
        this.prev.next();
    }


    handleAnimation(anim: any): void {
        this.anim = anim;
        this.anim.stop();
        //this.showStepper = true;
    }

    configReady(anim: any): void {
        this.anim.playSegments([0, 10], true)
    }


    sliderReady() {
        //this.showStepper = true;
        this.sliderCont.nativeElement.setAttribute('style', `width:${this.sliderCont.nativeElement.clientWidth - 1}px`);
        setTimeout(() => {
            this.sliderCont.nativeElement.removeAttribute('style')
        }, 100)
    }

    // enterFrame(frame: BMEnterFrameEvent) {

    //     console.log(Math.round(this.anim.currentFrame))

    //     // console.log(Math.round(this.anim.currentRawFrame))


    //     // if (Math.round(this.anim.currentRawFrame) === this.stopAnim) {
    //     //     this.pause();
    //     // }

    //     // this.ngZone.runOutsideAngular(() => {
    //     //     console.log(frame)
    //     //     this.currframe++

    //     //     console.log(this.currframe)

    //     //     if (this.currframe = 55) {
    //     //         this.pause();
    //     //     }
    //     // });




    // }


    // stop(): void {
    //     this.ngZone.runOutsideAngular(() => {
    //         this.anim.stop();
    //     });
    // }

    // play(): void {
    //     this.ngZone.runOutsideAngular(() => {
    //         this.anim.play();
    //     });
    // }
    // pause(): void {
    //     this.ngZone.runOutsideAngular(() => {
    //         this.anim.pause();
    //     });
    // }



    // stop() {
    //     this.anim.stop();
    // }

    // pause() {
    //     this.anim.pause();
    // }

    // play() {
    //     this.anim.play();
    // }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
