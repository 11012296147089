import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NotificationSnackbarComponent } from '../components/notification-snackbar/notification-snackbar.component';


export interface NotificationDataModel {
	message: string,
	label?: string,
	type?: 'success' | 'danger' | 'warning';
}

@Injectable({
	providedIn: 'root'
})

export class NotificationService {
	private snackBarConf: MatSnackBarConfig = {
		duration: 5000,
		horizontalPosition: 'right',
		verticalPosition: 'top',
	}
	constructor(
		private snackBar: MatSnackBar
	) { }


	openNotification(data: NotificationDataModel, configuration?: MatSnackBarConfig) {
		let conf = {
			...this.snackBarConf,
			...(configuration && { ...configuration }),
			data
		}
		this.snackBar.openFromComponent(NotificationSnackbarComponent, conf);
	}
	closeNotification() {
		this.snackBar.dismiss();
	}
}
