export const API_URL = {
	getAuthToken: 'https://apimgmt.synapsia.ai/public/token',
	getSpeechToken: 'https://apimgmt.synapsia.ai/public/token/speechservices',
	getForgotPassCode: 'https://apimgmt.synapsia.ai/public/user/forgotPassword',
	updatePassCode: 'https://apimgmt.synapsia.ai/user/updatepassword',
	googleRevokeToken: 'https://apimgmt.synapsia.ai/google/revoketoken/',
	googleToken: 'https://apimgmt.synapsia.ai/google/token',
	revokePartialgoogleToken: 'https://apimgmt.synapsia.ai/google/revokepartialtoken',
	getGoogleCalendar: 'https://apimgmt.synapsia.ai/calendar/read/',
	updateGoogleCalendar: 'https://apimgmt.synapsia.ai/calendar/update/',
	googlePlaceImage: 'https://apimgmt.synapsia.ai/public/google/placeimage/',
	getUser: 'https://apimgmt.synapsia.ai/user/read',
	updateUser: 'https://apimgmt.synapsia.ai/user/patch',
	getSettingsByIdUser: 'https://apimgmt.synapsia.ai/setting/readbyuser/',
	createSetting: 'https://apimgmt.synapsia.ai/setting/create',
	updateSetting: 'https://apimgmt.synapsia.ai/setting/update',
	createUser: 'https://apimgmt.synapsia.ai/public/user/create',
	deleteUser: 'https://apimgmt.synapsia.ai/user/disable',
	query: 'https://apimgmt.synapsia.ai/public/maestroai/query',
	conversationById: 'https://apimgmt.synapsia.ai/conversation/read/',
	// conversationById: 'https://apitest.synapsia.ai/conversation/readimpersonation/6fb3a7f4-bc4a-4d9c-96d5-fe41b1140449/',
	deleteConversationById: 'https://apimgmt.synapsia.ai/conversation/delete/',
	deleteConversationList: 'https://apimgmt.synapsia.ai/conversation/deletelist/',
	deleteConversationExchange: 'https://apimgmt.synapsia.ai/conversation/deletemsg/',
	cleanConversation: 'https://apimgmt.synapsia.ai/conversation/deletemsglist/',
	conversationKbById: 'https://apimgmt.synapsia.ai/conversation/readKB/',
	deleteConversationKbById: 'https://apimgmt.synapsia.ai/conversation/deleteKB/',
	conversetionGuest: 'https://apimgmt.synapsia.ai/conversation/readguest/',
	textToSpeech: 'https://apimgmt.synapsia.ai/maestroai/texttospeech',
	sharedTextToSpeech: 'https://apimgmt.synapsia.ai/public/maestroai/idanstexttospeech',
	readGraphById: 'https://apimgmt.synapsia.ai/personalgraph/read/',
	getContactsList: 'https://apimgmt.synapsia.ai/Contact/Read',
	addContact: 'https://apimgmt.synapsia.ai/Contact/Create',
	updateContact: 'https://apimgmt.synapsia.ai/Contact/Update',
	removeContact: 'https://apimgmt.synapsia.ai/Contact/Delete',
	readHtmlMail: 'https://apimgmt.synapsia.ai/email/readlivebyid/',
	readMaiaConv: 'https://apimgmt.synapsia.ai/conversation/readmaiaconversation',
	readMaiaConvPinned: 'https://apimgmt.synapsia.ai/conversation/readmaiaconversationpinnedpaged/',
	//readMaiaConvPinned: 'https://apidev.synapsia.ai/conversation/readmaiaconversationimpersonation/6fb3a7f4-bc4a-4d9c-96d5-fe41b1140449',
	readMaiaConvPaged: 'https://apimgmt.synapsia.ai/conversation/readmaiaconversationpaged/',
	stopAnswer: 'https://apidev.synapsia.ai/conversation/interruptconversation/',
	sendMail: 'https://apimgmt.synapsia.ai/public/mail/send',
	search: 'https://apimgmt.synapsia.ai/conversation/conversationcontactsearch',
	updateConversationTitle: 'https://apimgmt.synapsia.ai/conversation/titleupdate/',
	updateConversationPinned: 'https://apimgmt.synapsia.ai/conversation/pinupdate/',
	updateAdamAnswer: 'https://apimgmt.synapsia.ai/conversation/updateadamanswer/',
	userReportCreate: 'https://apimgmt.synapsia.ai/userreport/create',
	lastPpgEdit: 'https://apimgmt.synapsia.ai/personalgraph/updatelastppgedit',
	getPpgDictionary: 'https://apimgmt.synapsia.ai/personalgraph/readuserdictionary',
	//updatePpgListUser: 'https://apimgmt.synapsia.ai/personalgraph/updatelistuser',
	updatePpgCudUser: 'https://apimgmt.synapsia.ai/personalgraph/cuduser',
	//createNodeInfo: 'https://apimgmt.synapsia.ai/personalgraph/createuser',
	//deleteNodeInfo: 'https://apimgmt.synapsia.ai/personalgraph/delete/',
	//deleteListNodeInfo: 'https://apimgmt.synapsia.ai/personalgraph/deletelist',
	getBannedCat: 'https://apimgmt.synapsia.ai/personalgraph/readbannedppg',
	updateBannedCat: 'https://apimgmt.synapsia.ai/personalgraph/updatebannedppg',
	downloadPpg: 'https://apimgmt.synapsia.ai/personalgraph/downloadppg',
	readMaiaCalendar: 'https://apimgmt.synapsia.ai/calendar/readmaiacalendar',
	creaeteMaiaCalendar: 'https://apimgmt.synapsia.ai/calendar/createmaiacalendar',
	deleteMaiaCalendar: 'https://apimgmt.synapsia.ai/calendar/delete/',
	searchEvents: 'https://apimgmt.synapsia.ai/calendar/search',
	createMaiaEvent: 'https://apimgmt.synapsia.ai/calendar/createmaiaevent',
	deleteMaiaEvent: 'https://apimgmt.synapsia.ai/calendar/deletemaiaevent/',
	updateMaiaEvent: 'https://apimgmt.synapsia.ai/calendar/updatemaiaevent',
	readSharedChat: 'https://apimgmt.synapsia.ai/public/chatshare/readchat/',
	generateShareChat: 'https://apimgmt.synapsia.ai/chatshare/create/',
	readSharedConversations: 'https://apimgmt.synapsia.ai/chatshare/read',
	deleteSharedConversation: 'https://apimgmt.synapsia.ai/chatshare/delete/',
	udriveUploadFile: 'https://apimgmt.synapsia.ai/udrive/upload',
	udriveUploadUrl: 'https://apimgmt.synapsia.ai/udrive/uploadurl',
	udriveRead: 'https://apimgmt.synapsia.ai/udrive/readdrive',
	udriveCreateFolder: 'https://apimgmt.synapsia.ai/udrive/createfolder',
	udriveDeleteItem: 'https://apimgmt.synapsia.ai/udrive/delete/',
	udriveDeleteListItems: 'https://apimgmt.synapsia.ai/udrive/deletelist',
	udriveRenameFolder: 'https://apimgmt.synapsia.ai/udrive/renamefolder',
	udriveRenameFile: 'https://apimgmt.synapsia.ai/udrive/rename',
	udriveUpdateNotes: 'https://apimgmt.synapsia.ai/udrive/updatenotes/',
	udriveQuickActions: 'https://apimgmt.synapsia.ai/udrive/quickaction',
	udriveSaveInUdrive: 'https://apimgmt.synapsia.ai/udrive/uploadimage',
	udriveMove: 'https://apimgmt.synapsia.ai/udrive/movelist',
	udriveEmailRead: 'https://apimgmt.synapsia.ai/udriveemail/read',
	udriveEmailCreate: 'https://apimgmt.synapsia.ai/udriveemail/create',
	udriveEmailUpdate: 'https://apimgmt.synapsia.ai/udriveemail/update',
	udriveEmailDelete: 'https://apimgmt.synapsia.ai/udriveemail/delete/',
	calculateFileEmbeddings: 'https://apimgmt.synapsia.ai/udrive/calculatefileurlembeddings/',
	convDriveCreate: 'https://apimgmt.synapsia.ai/conversationdrive/create',
	convDriveUpdate: 'https://apimgmt.synapsia.ai/conversationdrive/update',
	convDriveCleanAttachments: 'https://apimgmt.synapsia.ai/conversationdrive/delete/',
	convDriveGetAttachments: 'https://apimgmt.synapsia.ai/conversationdrive/readattachments/',
	//convDriveGetAttachments: 'https://apimgmt.synapsia.ai/conversationdrive/readattachmentsimpersonation/6fb3a7f4-bc4a-4d9c-96d5-fe41b1140449/',
	convDriveDetach: 'https://apimgmt.synapsia.ai/conversationdrive/deletelistbyudriveIdlist',
	uknowCreateProfile: 'https://apimgmt.synapsia.ai/public/uknow/createprofiling',
	uknowReadById: 'https://apimgmt.synapsia.ai/uknow/read/',
	uknowVerify: 'https://apimgmt.synapsia.ai/uknow/verify/',
	uknowCreate: 'https://apimgmt.synapsia.ai/uknow/create/',
	uknowUpdate: 'https://apimgmt.synapsia.ai/uknow/update/',
	uknowDelete: 'https://apimgmt.synapsia.ai/uknow/delete/',
	uKnowReadAttachments: 'https://apimgmt.synapsia.ai/public/uknow/readattachmentsbyhost',
	uknowReadAll: 'https://apimgmt.synapsia.ai/uknow/read',
	uknowReadGuestConversation: 'https://apimgmt.synapsia.ai/conversation/readguest/',
	searchMaiaConversation: 'https://apimgmt.synapsia.ai/conversation/searchmaiaconversation',
	videoShareRead: 'https://apimgmt.synapsia.ai/videoshare/read',
	videoShareReadByKey: 'https://apimgmt.synapsia.ai/videoshare/readbykey/',
	videoShareCreate: 'https://apimgmt.synapsia.ai/videoshare/create',
	videoShareUpdateTitle: 'https://apimgmt.synapsia.ai/videoshare/updatetitle',
	videoShareDownload: 'https://apimgmt.synapsia.ai/videoshare/download/',
	videoShareDownloadLocal: 'https://apimgmt.synapsia.ai/videoshare/downloadlocal/',
	videoShareDownloadFree: 'https://apimgmt.synapsia.ai/videoshare/downloadfree',
}