import { Component } from '@angular/core';

@Component({
	selector: 'maia-forced-update',
	templateUrl: './forced-update.component.html',
	styleUrls: ['./forced-update.component.scss']
})
export class ForcedUpdateComponent {


	refreshApp() {
		document.location.href = document.location.origin;
	}
}
