<div class="flex flex-col text-center px-2" *ngIf="!isPersonalSetting">
    <span *ngIf="isMobileView" class="text-green-dark-900 text-3xl py-7">{{ "SIGN_UP.last_step_title" | translate }}</span>
    <span class="text-lg md:text-left md:pt-5">{{ "SIGN_UP.last_step_subtitle" | translate }}</span>
</div>

<form class="md:pt-3" [ngClass]="!isPersonalSetting  ? 'pt-20' : 'pt-3'" [formGroup]="form" (ngSubmit)="onSubmit.emit()">
    <div class="flex flex-col">
        <label for="bio" class="text-green-dark-900 text-xs pl-2 mb-1">{{ "SIGN_UP.something_about_you" | translate }}</label>
        <textarea class="bg-grey-light min-h-[190px] pl-2 border-b border-green-dark-900 focus:outline-none focus:border-green-light-600" id="bio" formControlName="bio" placeholder=""></textarea>
    </div>
</form>