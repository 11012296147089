import { TagSelectOption } from "./generic.model"

export interface SettingModel {
	id?: number,
	id_persona?: number | null,
	key: string,
	user_id: string,
	value: string
}

export enum PolicyType {
	localizationPolicy = "localizationPolicy",
	mailCalPolicy = "mailCalPolicy",
	microphonePolicy = "microphonePolicy",
	custMarkPolicy = "custMarkPolicy",
	dataThirdPartPolicy = "dataThirdPartPolicy",
}

export interface UserInformations {
	id?: string,
	id_email?: string,
	id_uup?: string | null,
	displayName?: string,
	name?: string,
	surname?: string,
	email?: string,
	profilePhoto?: string | null,
	sex?: string,
	idRole?: number,
	banned?: boolean,
	disabled?: boolean,
	address?: string | null,
	birthDate?: string | null,
	bio?: string | null,
	language?: string,
	ocpApimSubscriptionKey?: string | null,
	interests?: string[] | null,
	country: string,
	[PolicyType.localizationPolicy]: boolean;
	[PolicyType.mailCalPolicy]: boolean;
	[PolicyType.microphonePolicy]: boolean;
	[PolicyType.custMarkPolicy]: boolean;
	[PolicyType.dataThirdPartPolicy]: boolean;
}

export interface SourceManagement {
	blackList: string[],
	whiteList: string[]
}

export enum NotificationType {
	browser_notifications = "browser_notifications",
	sound_browser_notifications = "sound_browser_notifications",
	mail_notifications = "mail_notifications",
	calendar_events = "calendar_events"

}

export interface SettingNotification {
	[NotificationType.browser_notifications]: boolean;
	[NotificationType.sound_browser_notifications]: boolean;
	[NotificationType.mail_notifications]: boolean;
	[NotificationType.calendar_events]: boolean;
}


export enum SettingsKeyName {
	MAIA_APPLICATION = 'maiaApplication',
	CONVERSATION_TITLE_BY_USER_LIST = 'convTitleByUserList',
	TIMEZONE = 'timeZone',
	BANNED_CATEGORY = 'bannedCategory',
	LAST_PPG_UPDATE = 'lastPPGUpdate',
	GOOGLE_TOKEN = 'googleToken',
	GOOGLE_CALENDAR = 'googleCalendar'
}