import { NgModule } from '@angular/core';
import { StepperComponent } from './stepper.component';
import { StepperSectionDirective } from './stepper-section.directive';


@NgModule({
  declarations: [
    StepperSectionDirective
  ],
  imports: [
    StepperComponent
  ],
  exports:[
    StepperComponent,
    StepperSectionDirective
  ]
})
export class StepperModule { }
