<div class="text-5xl text-white" style="text-shadow:.1rem .1rem .1rem rgba(0,0,0,.6)">
	{{'COMMON.under_maintainance' | translate}}
</div>
<div class="w-11/12 max-w-[15rem] mr-[11%] mb-24 mt-28">
	<div class="relative pt-[100%]">
		<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 44 44" class="cog-1">
			<path fill="#1986CF" fill-rule="evenodd" d="M18.8.4 18 7.1l-3.5 1.6-5.4-4.5-4.6 4.7 4.4 5.5-1.6 3.3-6.6.8S.4 24 .4 25.1c2 .1 6.8 1 6.8 1l1.5 3.4-4.3 5.3L9 39.6l5.3-4.3 3.4 1.7 1 6.1 6.6.3s.5-6.2 1.2-6.4c.7-.2 3.5-1.6 3.5-1.6l5.1 4.1s4.4-4.4 4.4-4.9-3.8-4.8-3.8-4.8 1.6-3.2 1.6-3.6 6.3-1.1 6.3-1.1l.2-6.5s-6.4-.6-6.6-1c-.2-.4-.9-2.5-1.5-3.7 1.2-1.4 4.2-5.1 4.2-5.1l-4.7-4.5-5.2 4L26.3 7 25.5.5 18.8.4z" />
		</svg>
		<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 44 44" class="cog-2">
			<path fill="#1986CF" fill-rule="evenodd" d="M18.8.4 18 7.1l-3.5 1.6-5.4-4.5-4.6 4.7 4.4 5.5-1.6 3.3-6.6.8S.4 24 .4 25.1c2 .1 6.8 1 6.8 1l1.5 3.4-4.3 5.3L9 39.6l5.3-4.3 3.4 1.7 1 6.1 6.6.3s.5-6.2 1.2-6.4c.7-.2 3.5-1.6 3.5-1.6l5.1 4.1s4.4-4.4 4.4-4.9-3.8-4.8-3.8-4.8 1.6-3.2 1.6-3.6 6.3-1.1 6.3-1.1l.2-6.5s-6.4-.6-6.6-1c-.2-.4-.9-2.5-1.5-3.7 1.2-1.4 4.2-5.1 4.2-5.1l-4.7-4.5-5.2 4L26.3 7 25.5.5 18.8.4zM22 15.1c3.8 0 6.9 3.1 6.9 6.9s-3.1 6.9-6.9 6.9c-3.8 0-6.9-3.1-6.9-6.9 0-3.8 3.1-6.9 6.9-6.9z" />
		</svg>
		<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 44 44" class="cog-3">
			<path fill-rule="evenodd" d="M18.8.4 18 7.1l-3.5 1.6-5.4-4.5-4.6 4.7 4.4 5.5-1.6 3.3-6.6.8S.4 24 .4 25.1c2 .1 6.8 1 6.8 1l1.5 3.4-4.3 5.3L9 39.6l5.3-4.3 3.4 1.7 1 6.1 6.6.3s.5-6.2 1.2-6.4c.7-.2 3.5-1.6 3.5-1.6l5.1 4.1s4.4-4.4 4.4-4.9-3.8-4.8-3.8-4.8 1.6-3.2 1.6-3.6 6.3-1.1 6.3-1.1l.2-6.5s-6.4-.6-6.6-1c-.2-.4-.9-2.5-1.5-3.7 1.2-1.4 4.2-5.1 4.2-5.1l-4.7-4.5-5.2 4L26.3 7 25.5.5 18.8.4zM22 15.1c3.8 0 6.9 3.1 6.9 6.9s-3.1 6.9-6.9 6.9c-3.8 0-6.9-3.1-6.9-6.9 0-3.8 3.1-6.9 6.9-6.9z" />
		</svg>
		<img src="/assets/images/m_maia.png" alt="" class="w-2/5 h-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
	</div>
</div>
<div class="bg-white rounded-md p-4 w-11/12 max-w-xl flex flex-col items-center justify-center">
	<div class="text-green-light-700">
		{{message}}
	</div>
	<button class="transition-colors flex items-center shadow-custom rounded-md py-1 px-2 mt-4 text-white bg-green-light-700 hover:bg-green-light" (click)="goToMaia()">
		<span class="leading-4 mr-1">{{'CHATSHAREPAGE.goto' | translate}}</span>
		<i class="icon-maia text-3xl"></i>
	</button>
</div>