import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LastStepSectionComponent } from './last-step-section.component';
import { LottieModule } from 'ngx-lottie';
import { playerFactory } from 'src/app/app.module';

@NgModule({
    declarations: [LastStepSectionComponent],
    exports: [LastStepSectionComponent],
    imports: [
        CommonModule, TranslateModule,
        LottieModule.forRoot({ player: playerFactory })],
    providers: [],
})
export class LastStepSectionModule { }
