import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, ContentChildren, CUSTOM_ELEMENTS_SCHEMA, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output, QueryList, ViewChild, } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { Pagination, Navigation, SwiperOptions } from 'swiper';
import { StepperSectionDirective } from './stepper-section.directive';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription, take } from 'rxjs';
import { StepperBpConfig } from '@models/generic.model';
register();
@Component({
    selector: 'maia-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [CommonModule, TranslateModule],
    standalone: true,
})
export class StepperComponent implements AfterContentInit, OnDestroy {
    @ContentChildren(StepperSectionDirective)
    sectionDirs!: QueryList<StepperSectionDirective>;
    @ViewChild('stepper', { static: false }) stepper!: ElementRef;
    @Output() stepperStatus: EventEmitter<{ index: number; done: boolean }> = new EventEmitter();
    @Output() stepperAnimationControl: EventEmitter<{ index: number; direction: string }> = new EventEmitter();
    @Output() stepperInit: EventEmitter<void> = new EventEmitter();
    @Input() next!: Observable<void>;
    @Input() prev!: Observable<void>;
    @Input() isDisabled!: boolean;
    @Input() currIndex: number = 0;
    @Input() navigation: boolean = false;
    @Input() className!: string;
    @Input() centeredSlides: boolean = true;
    @Input() spaceBetween: number = 0;
    @Input() slidesPerView: number | 'auto' = 1;
    @Input() autoHeight: boolean = true;
    @Input() breakpoints!: StepperBpConfig;
    @Input() height: null | number = null;
    private subscriptions = new Subscription();
    ranDnumber = Math.floor(Math.random() * 10000) + 1;
    showNav: boolean = false;
    sections!: StepperSectionDirective[];
    constructor(
        private _ngZone: NgZone
    ) { }
    public config: SwiperOptions = {
        pagination: {
            el: '.swiper-custom-pagination' + this.ranDnumber,
            type: 'bullets',
            clickable: true
        },
        injectStyles: [
            `
            swiper-container {
                height:100%;
            }
            .swiper-wrapper {
              height:100%;
            }
            swiper-slide {
                height:100%;
            }
            .swiper-fade-slide swiper-slide {
                opacity:0;
                transition:opacity 500ms linear;
            }
            .swiper-fade-slide swiper-slide.swiper-slide-active {
                opacity:1;
            }
            .swiper-pagination-bullet {
                background:var(--grey);
                opacity:1;
                transition:width 200ms linear;
                border-radius:4px;
                margin:4px !important;
            }
            .swiper-pagination-bullet-active {
                background:var(--green-light);
                width:44px;
            }
            .swiper-button-next, .swiper-button-prev {
                color: var(--green-light);
            }
            `,
        ],
        on: {
            slideChange: () => {
                this.stepperStatus.emit({
                    index: this.stepper.nativeElement.swiper.activeIndex,
                    done:
                        this.stepper.nativeElement.swiper.activeIndex >= this.sections.length - 1,
                });
            },
            slideNextTransitionStart: () => {
                this.stepperAnimationControl.emit({
                    index: this.stepper.nativeElement.swiper.activeIndex,
                    direction: 'next'
                })
            },
            slidePrevTransitionStart: () => {
                this.stepperAnimationControl.emit({
                    index: this.stepper.nativeElement.swiper.activeIndex,
                    direction: 'prev'
                })
            },
            resize: () => {
                this.initSlider();
            },
            init: () => {
                this.showNav = true;
                this.stepperInit.emit();
            }
        },
    };
    ngAfterContentInit() {
        this._ngZone.onStable
            .pipe(take(1))
            .subscribe(() => {
                setTimeout(() => {
                    this.initSlider()
                }, 100)
            });
        if (this.next) this.subscriptions.add(this.next.subscribe(() => this.onNext()));
        if (this.prev) this.subscriptions.add(this.prev.subscribe(() => this.onPrev()));
    };


    private initSlider() {
        this.sections = this.sectionDirs.toArray();
        this.config = {
            ...this.config,
            allowTouchMove: !this.isDisabled,
            initialSlide: this.currIndex,
            ...(this.navigation ? {
                navigation: {
                    nextEl: '.swiper-button-next-unique' + this.ranDnumber,
                    prevEl: '.swiper-button-prev-unique' + this.ranDnumber
                },
            } : null),
            ...(!this.breakpoints && this.centeredSlides ? {
                centeredSlides: this.centeredSlides
            } : null),
            ...(!this.breakpoints && this.slidesPerView ? {
                slidesPerView: this.slidesPerView
            } : null),
            ...(!this.breakpoints && this.spaceBetween ? {
                spaceBetween: this.spaceBetween
            } : null),
            autoHeight: this.autoHeight,
            height: this.height,
            breakpoints: this.breakpoints,
        }
        Object.assign(this.stepper.nativeElement, this.config);
        this.stepper.nativeElement.initialize();
    }


    onNext() {
        this.stepper.nativeElement.swiper.slideNext();
    }
    onPrev() {
        this.stepper.nativeElement.swiper.slidePrev();
    }

    ngOnDestroy(): void {
        if (this.stepper.nativeElement.swiper) this.stepper.nativeElement.swiper.destroy(true, true);
        this.subscriptions.unsubscribe();
    }
}
