import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AddContactParams, Contact, DeleteContactParams } from '@models/contacts.model';
import { environment } from '@environments/environment';
import { API_URL } from '@constants/api.constant'

@Injectable({
	providedIn: 'root',
})
export class ContactsService {
	constructor(private http: HttpClient) { }

	getContacts(userId: string): Observable<Contact[]> {
		return this.http
			.get<Contact[]>(`${API_URL.getContactsList}/${userId}`)
			.pipe(
				map((res: Contact[]) => {
					return res;
				}),
				catchError(() => {
					return of([]);
				})
			);
	}

	addContact(params: AddContactParams): Observable<Contact> {
		return this.http.post(`${API_URL.addContact}`, params).pipe(
			map((res: any) => {
				return res;
			})
		);
	}

	updateContact(params: Contact): Observable<Contact> {
		return this.http.post(`${API_URL.updateContact}`, params).pipe(
			map((res: any) => {
				return res;
			})
		);
	}

	deleteContact(params: DeleteContactParams): Observable<void> {
		const { user_id, contact_id } = params;
		return this.http
			.post(`${API_URL.removeContact}/${user_id}/${contact_id}`, null)
			.pipe(
				map((res: any) => {
					return res;
				})
			);
	}
}
