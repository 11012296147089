import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AnimationOptions } from 'ngx-lottie';
import { Subject, takeUntil } from 'rxjs';
import { ButtonType } from '@models/generic.model';
import { LoginModel } from '@models/login.model';
import { AuthService } from '@services/auth.service';
import { MobileViewService } from '@services/isMobileView.service';
import { LocalStorageService } from '@services/locaStorage.service';
import { AppRoutes } from '@utils/routes';


@Component({
	selector: 'maia-welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
	btnType: typeof ButtonType = ButtonType;
	anim: any;
	email: string;
	password: string;
	personName: string;
	options: AnimationOptions = {
		path: '/assets/images/welcome_m_tiny.json',
		renderer: 'svg',
		loop: true,
		autoplay: false
	};
	playerDimension!: number;
	private destroyed$: Subject<boolean> = new Subject<boolean>();
	constructor(
		private localStorage: LocalStorageService,
		private authService: AuthService,
		private router: Router,
		private mobileViewService: MobileViewService,
		private $gaService: GoogleAnalyticsService
	) {

		let tmpUser = this.localStorage.getItem('maia_tmpUser');
		this.email = tmpUser.email;
		this.password = tmpUser.password;
		this.personName = tmpUser.personName;
		this.mobileViewService.isMobile.pipe(takeUntil(this.destroyed$)).subscribe({
			next: (isMobileView) => {
				if (isMobileView) {
					this.playerDimension = window.innerWidth
				} else {
					this.playerDimension = 500
				}
			},
		});

	}

	ngOnInit(): void {
		this.$gaService.gtag('config', 'AW-11391733242')
		this.$gaService.gtag('event', 'conversion', { 'send_to': 'AW-11391733242/sSe4COOIkfEYEPqbgLgq' });
	}



	handleAnimation(anim: any): void {
		this.anim = anim;
		this.anim.stop();
	}

	configReady(anim: any): void {
		this.anim.play()
	}


	loopCompleted(anim: any): void {
		this.anim.playSegments([30, 125], true);
	}


	logIn(): void {
		this.authService.signIn(this.email, this.password).subscribe({
			next: (response: LoginModel) => {
				this.localStorage.removeItem('maia_tmpUser');
				this.localStorage.setItem('maia_firstLogin', true);
				this.authService.setToken(response);
				this.router.navigate([AppRoutes.MYMAIA]);
			},
			error: (res: HttpErrorResponse) => {
				console.log(res.error)
			}
		})
	}
}
