<div class="flex flex-col">
    <label class="text-green-dark-900 text-xs pl-2 mb-1">{{ title }}</label>
    <div class="flex flex-wrap gap-2">
        <div class="rounded-lg flex items-center justify-center border-2 select-none" (click)="selectOption(option)"
             *ngFor="let option of options"
             [ngClass]="elementExist(option) ? 'bg-green-light text-white border-green-light' : 'border-grey-dark text-grey-dark'">
            <div *ngIf="type === selectType.SINGLE_SELECT" class="rounded-full w-5 h-5 m-2"
                 [ngClass]="elementExist(option) ? 'border-[6px] border-white bg-green-light-900' : 'border-2 border-grey-dark bg-transparent'">
            </div>
            <div *ngIf="type === selectType.MULTI_SELECT" class="rounded w-5 h-5 m-2 bg-white"
                 [ngClass]="elementExist(option) ? '' : 'border-2 border-grey-dark'">
                <div *ngIf="elementExist(option)"
                     class="border-b-[3px] translate-y-[0.55rem] border-green-dark-900 px-2 rotate-45">
                </div>
                <div *ngIf="elementExist(option)"
                     class="border-b-[3px] translate-y-[0.35rem] border-green-dark-900 px-2 -rotate-45"></div>
            </div>
            <span class="text-sm font-medium mr-3 mt-1 cursor-pointer">{{option.name | translate}}</span>
        </div>
    </div>
</div>