import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InterestsSectionComponent } from './interests-section.component';
import { TagSelectModule } from '../../tag-select/tag-select.module';

@NgModule({
  declarations: [InterestsSectionComponent],
  exports: [InterestsSectionComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    TagSelectModule,
  ],
  providers: [],
})
export class InterestSectionModule {}
