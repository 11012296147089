import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TagSelectOption, TagSelectType } from '@models/generic.model';


@Component({
	selector: 'maia-tag-select',
	templateUrl: './tag-select.component.html',
	styleUrls: ['./tag-select.component.scss'],
})
export class TagSelectComponent implements OnInit {
	@Input() title: string = '';
	@Input() type: TagSelectType = TagSelectType.SINGLE_SELECT;
	@Input() options: Array<TagSelectOption> = [];
	@Input() selectedOptions: Array<TagSelectOption> = [];
	@Output() selectedValues: EventEmitter<TagSelectOption[]> = new EventEmitter();

	selectType: typeof TagSelectType = TagSelectType;

	constructor() { }

	ngOnInit(): void { }

	elementExist(element: TagSelectOption): boolean {
		return this.selectedOptions.includes(element)
	}

	selectOption(option: TagSelectOption) {
		this.selectedValues.emit(
			this.selectedOptions.includes(option)
				? this.selectedOptions.filter((o) => o.id !== option.id)
				: this.type === TagSelectType.MULTI_SELECT
					? [...this.selectedOptions, option]
					: [option]
		);
	}
}
