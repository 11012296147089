import {
	animate,
	query,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';

export const animationStateProperty = 'animationNumber';

export const slideInFromRightAnimation = trigger('slideInFromRightAnimation', [
	state('in', style({ transform: 'translateX(0)' })),
	transition('void => *', [
		style({ transform: 'translateX(100%)' }),
		animate('{{duration}}ms ease-in-out'),
	]),
]);

export const slideInFromLeftAnimation = [
	trigger('slideInFromLeftAnimation', [
		state('in', style({ transform: 'translateX(0)' })),
		transition('void => *', [
			style({ transform: 'translateX(-100%)' }),
			animate('{{duration}}ms ease-in-out'),
		]),
	]),
];

export const slideInFromTopAnimation = [
	trigger('slideInFromTopAnimation', [
		state('in', style({ transform: 'translateY(0)' })),
		transition('void => *', [
			style({ transform: 'translateY(-100%)' }),
			animate('{{duration}}ms ease-in-out'),
		]),
	]),
];

export const slideInFromBottomAnimation = [
	trigger('slideInFromBottom', [
		transition('* => *', [
			query(
				':enter',
				[
					style({
						opacity: 0,
						transform: 'translateY(100%)',
						//position: 'absolute',
					}),
				],
				{
					optional: true,
				}
			),
			query(
				':leave',
				[
					style({
						opacity: 1,
						transform: 'translateY(0)',
						//position: 'relative',
					}),
					animate(
						'0.3s',
						style({
							opacity: 0,
							transform: 'translateY(100%)',
							//position: 'absolute',
						})
					),
				],
				{ optional: true }
			),
			query(
				':enter',
				[
					style({
						opacity: 0,
						transform: 'translateY(100%)',
						//position: 'absolute',
					}),
					animate(
						'0.3s',
						style({
							opacity: 1,
							transform: 'translateY(0)',
							//position: 'relative',
						})
					),
				],
				{ optional: true }
			),
		]),
	]),
];

export function routerTransition() {
	return trigger('routerAnimation', [
		// LEFT TO RIGHT AKA RESET
		transition('* => 0', [
			// Initial state of new route
			query(
				':enter',
				style({
					position: 'fixed',
					width: '100%',
					height: '100%',
					transform: 'translateX(-100%)',
				}),
				{ optional: true }
			),
			// move page off screen right on leave
			query(
				':leave',
				animate(
					'0.3s ease-in-out',
					style({
						position: 'fixed',
						width: '100%',
						height: '100%',
						transform: 'translateX(100%)',
					})
				),
				{ optional: true }
			),
			// move page in screen from left to right
			query(
				':enter',
				animate(
					'0.3s ease-in-out',
					style({
						opacity: 1,
						transform: 'translateX(0%)',
					})
				),
				{ optional: true }
			),
		]),
		// LEFT TO RIGHT AKA PREVIOUS
		transition('* => 1', [
			// Initial state of new route
			query(
				':enter',
				style({
					position: 'fixed',
					width: '100%',
					height: '100%',
					transform: 'translateX(-100%)',
				}),
				{ optional: true }
			),
			// move page off screen right on leave
			query(
				':leave',
				animate(
					'0.3s ease-in-out',
					style({
						position: 'fixed',
						width: '100%',
						height: '100%',
						transform: 'translateX(100%)',
					})
				),
				{ optional: true }
			),
			// move page in screen from left to right
			query(
				':enter',
				animate(
					'0.3s ease-in-out',
					style({
						opacity: 1,
						transform: 'translateX(0%)',
					})
				),
				{ optional: true }
			),
		]),
		// RIGHT TO LEFT AKA NEXT
		transition('* => 2', [
			// Initial state of new route
			query(
				':enter',
				style({
					position: 'fixed',
					width: '100%',
					height: '100%',
					transform: 'translateX(100%)',
				}),
				{ optional: true }
			),
			// move page off screen right on leave
			query(
				':leave',
				animate(
					'0.3s ease-in-out',
					style({
						position: 'fixed',
						width: '100%',
						height: '100%',
						transform: 'translateX(-100%)',
					})
				),
				{ optional: true }
			),
			// move page in screen from left to right
			query(
				':enter',
				animate(
					'0.3s ease-in-out',
					style({
						opacity: 1,
						transform: 'translateX(0%)',
					})
				),
				{ optional: true }
			),
		]),
	]);
}

export const modalAnimation = [
	trigger('slideContent', [
		state(
			'void',
			style({ transform: 'translate3d(0, 25%, 0) scale(0.9)', opacity: 0 })
		),
		state('enter', style({ transform: 'none', opacity: 1 })),
		state('leave', style({ transform: 'translate3d(0, 25%, 0)', opacity: 0 })),
		transition('* => *', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
	]),
];

export const contactSelectAnimation = [
	trigger('contactSelectAnimation', [
		transition(':enter', [
			style({ transform: 'translateX(100%)', opacity: 0 }),
			animate(
				'{{duration}}ms ease-out',
				style({ transform: 'translateX(0)', opacity: 1 })
			),
		]),
		transition(':leave', [
			style({ transform: 'translateX(0)', opacity: 1 }),
			animate(
				'{{duration}}ms ease-in',
				style({ transform: 'translateX(100%)', opacity: 0 })
			),
		]),
	]),
];
