import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as udriveActions from '@store/actions/udrive.action';
import * as ChatActions from '@store/actions/chat.action';
import * as UdriveSelectors from '@store/selectors/udrive.selector';
import * as ChatSelectors from '@store/selectors/chat.selector';
import * as userSelectors from '@store/selectors/user.selector'
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { UdriveService } from '@services/udrive.service';
import { ActionType, QuickActionType, UdriveFileTreeModel, UdriveReadResponseModel } from '@models/udrive.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ChatSidebarItemModel, ConvDriveCUModel } from '@models/chat.model';
import { ChatService } from '@services/chat.service';
import { v4 as uuid } from 'uuid';
import { Router } from '@angular/router';
import { AppRoutes } from '@utils/routes';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class UdriveEffects {
	actionType = ActionType;
	ReadDrive$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.getUdrive),
			withLatestFrom(
				this.store.select(ChatSelectors.selectChatAttachments)
			),
			switchMap(([action, currAttachedChat]) => {
				return this.udriveService.readDrive().pipe(
					map((res: UdriveReadResponseModel) => {
						return udriveActions.setUdrive({ udriveResponse: res, chatAttachLength: currAttachedChat?.attachments.length || 0 })
					})
				)
			})
		);
	});

	AddFolder$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.addUdriveFolder),
			withLatestFrom(
				this.store.select(UdriveSelectors.currInputValue),
				this.store.select(UdriveSelectors.currPath)
			),
			switchMap((data) => {
				let newFolder = {
					file_name: data[1],
					path: data[2],
				}
				return this.udriveService.addFolder(newFolder).pipe(
					map(() => {
						return udriveActions.resetAction();
					})
				)
			})
		);
	});



	AddFile$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.addUdriveFile),
			switchMap((action) => {
				return this.udriveService.uploadFile(action.formData).pipe(
					map(() => {
						return udriveActions.setCurrAction({ value: this.actionType.NONE })
					})
				)
			})
		);
	});

	AddUrl$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.addUdriveUrl),
			withLatestFrom(
				this.store.select(UdriveSelectors.currInputValue),
				this.store.select(UdriveSelectors.currPath)
			),
			switchMap((data) => {
				let newUrl = {
					file_name: data[1].indexOf('http://') >= 0 || data[1].indexOf('https://') >= 0 ? data[1] : 'https://' + data[1],
					path: data[2] === '/' ? '/' : '/' + data[2]
				}
				return this.udriveService.uploadUrl(newUrl).pipe(
					map(() => {
						return udriveActions.resetAction();
					}),
					catchError((res: HttpErrorResponse) => {
						return of(udriveActions.urlUploadError());
					})
				)
			})
		);
	});
	DeleteFile$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.deleteUdriveItem),
			switchMap((action) => {
				return this.udriveService.deleteListItems([action.value]).pipe(
					map((res) => {
						return udriveActions.removeUdriveItem({ removedFiles: res })
					}),
					catchError((res: HttpErrorResponse) => {
						if (res.status === 409) {
							return of(udriveActions.DetachNeeded({ value: JSON.parse(res.error.developerMessage) }));
						} else {
							return of(udriveActions.NoopAction());
						}
					})
				)
			})
		);
	});
	DeleteSelectedAttachments$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.deleteSelectedAttachments),
			withLatestFrom(
				this.store.select(UdriveSelectors.currMultiSelectionList)
			),
			switchMap(([action, list]) => {
				return this.udriveService.deleteListItems(list).pipe(
					map((res) => {
						return udriveActions.removeUdriveItem({ removedFiles: res })
					}),
					catchError((res: HttpErrorResponse) => {
						if (res.status === 409) {
							return of(udriveActions.DetachNeeded({ value: JSON.parse(res.error.developerMessage) }));
						} else {
							return of(udriveActions.NoopAction());
						}
					})
				)
			})
		);
	});
	RenameFolder$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.renameUdriveFolder),
			tap(() => {
				this.store.dispatch(udriveActions.loadUDrive({ value: true }))
			}),
			withLatestFrom(
				this.store.select(UdriveSelectors.currInputValue),
				this.store.select(UdriveSelectors.currPath),
				this.store.select(UdriveSelectors.currEditedNode)
			),
			switchMap((data) => {
				let newItem: UdriveFileTreeModel = {
					...data[3]!,
					file_name: data[1]
				}
				if (newItem.subfile && newItem.subfile!.length >= 1) {
					let newSubFile = newItem.subfile.map((itm: UdriveFileTreeModel): UdriveFileTreeModel => {
						return {
							...itm,
							path: data[2] + data[1]
						}
					})
					newItem = {
						...newItem,
						subfile: newSubFile
					}
				}
				return this.udriveService.renameFolder(newItem).pipe(
					map((res: any) => {
						return udriveActions.resetAction();
					})
				)
			})
		);
	});

	RenameFile$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.renameUdriveFile),
			tap(() => {
				this.store.dispatch(udriveActions.loadUDrive({ value: true }))
			}),
			withLatestFrom(
				this.store.select(UdriveSelectors.currInputValue),
				this.store.select(UdriveSelectors.currEditedNode)
			),
			switchMap((data) => {
				let newItem: UdriveFileTreeModel = {
					...data[2]!,
					file_name: data[1] + data[2]?.extension
				}
				return this.udriveService.renameFile(newItem).pipe(
					map((res: any) => {
						return udriveActions.resetAction();
					})
				)
			})
		);
	});

	UpdateNotes$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.updateFileNotes),
			withLatestFrom(
				this.store.select(UdriveSelectors.currEditedNode)
			),
			switchMap((data) => {
				return this.udriveService.updateNotes(data[0].notes, data[1]!.id).pipe(
					map(() => {
						return udriveActions.loadUDrive({ value: false })
					})
				)
			})
		);
	});

	MoveItem$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.moveUdriveNode),
			switchMap((action) => {
				return this.udriveService.moveItem(action.value).pipe(
					map(() => {
						return udriveActions.setMultiSelectionList({ list: [], canMultiselect: true })
					})
				)
			})
		);
	});

	SelectMultiSelectionItem$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.selectMultiSelectionItem),
			withLatestFrom(
				this.store.select(UdriveSelectors.currMultiSelectionList),
				this.store.select(ChatSelectors.selectChatAttachments)
			),
			map(([action, selectionList, currAttachedChat]) => {
				let tmpList = [...selectionList]
				if (tmpList.indexOf(action.value) >= 0) {
					tmpList = tmpList.filter(e => e !== action.value);
				} else {
					tmpList.push(action.value);
				}
				return udriveActions.setMultiSelectionList({ list: tmpList, canMultiselect: tmpList.length + (currAttachedChat?.attachments.length || 0) < 11 })
			})
		)
	});



	SelectExpandedFoldersList$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.selectExpandedFoldersList),
			withLatestFrom(
				this.store.select(UdriveSelectors.currExpandedFolders)
			),
			map((data) => {
				let tmpList = [...data[1]]
				if (tmpList.indexOf(data[0].value) >= 0) {
					tmpList = tmpList.filter(e => e !== data[0].value);
				} else {
					tmpList.push(data[0].value);
				}
				return udriveActions.setExpandedFoldersList({ value: tmpList })
			})
		)
	});

	viewDetachableConvt$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.ViewDetachableConv),
			withLatestFrom(
				this.store.select(ChatSelectors.selectChatList),
				(action, chatList) => {
					return chatList?.find((chat: ChatSidebarItemModel) => {
						return chat.convId === action.value;
					})
				}
			),
			tap(() => {
				this.store.dispatch(udriveActions.setSidePanelOpen({ value: false }));
			}),
			map((chat) => {
				return ChatActions.SelectChat({ value: chat! });
			})
		)
	});


	chatDetach$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.ChatDetach),
			withLatestFrom(
				this.store.select(UdriveSelectors.currAttachedChat),
				(action, currAttachedChat) => {
					let tmpAttach = JSON.parse(JSON.stringify(currAttachedChat));
					tmpAttach.conversationList = [...action.detachList];
					return [tmpAttach]
				}
			),
			switchMap(([tmpAttach]) => {
				return this.chatService.convDriveDetach(tmpAttach).pipe(
					tap(() => {
						this.store.dispatch(udriveActions.DetachNeeded({ value: null }))
					}),
					map(() => {
						return udriveActions.deleteUdriveItem({ value: tmpAttach[0].idUdrive });
					})
				)
			})
		)
	});

	calculateFileEmbeddings$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.CalculateFileEmbeddings),
			switchMap((action) => {
				let attachId = action.attachId;
				return this.chatService.calculateFileEmbeddings(attachId).pipe(
					map(() => {
						return ChatActions.CalculateFileEmbeddings({ attachId });
					})
				)
			})
		)
	});

	setSidePanelOpen$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.setSidePanelOpen),
			map((action) => {
				if (action.value) {
					return udriveActions.getUdrive();
				} else {
					return udriveActions.NoopAction();
				}
			})
		)
	});


	getEmailAddress$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.GetEmailAddress),
			switchMap((action) => {
				return this.udriveService.readMail().pipe(
					map(res => {
						return udriveActions.SetEmailAddress({ emailAddress: res });
					})
				)
			})
		)
	});

	addEmailAddress$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.AddEmailAddress),
			switchMap((action) => {
				return this.udriveService.createMail(action.newMail).pipe(
					map(res => {
						return udriveActions.GetEmailAddress()
					})
				)
			})
		)
	});

	updateEmailAddress$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.UpdateEmailAddress),
			switchMap((action) => {
				return this.udriveService.updateMail(action.newMail).pipe(
					map(res => {
						return udriveActions.GetEmailAddress()
					})
				)
			})
		)
	});

	deleteEmailAddress$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.DeleteEmailAddress),
			switchMap((action) => {
				return this.udriveService.deleteMail(action.mailId).pipe(
					map(res => {
						return udriveActions.GetEmailAddress()
					})
				)
			})
		)
	});

	createNewChatAttachments$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.createNewChatAttachments),
			withLatestFrom(
				this.store.select(userSelectors.selectCurrentUser)
			),
			switchMap(([action, user]) => {
				let convId = uuid();
				let tmpAttachments: { id: number; order: number; }[] = [];
				action.selectedFile.forEach((newAttach, index) => {
					tmpAttachments.push({
						id: newAttach,
						order: index
					})
				})
				let body: ConvDriveCUModel = {
					conversation_id: convId,
					id_user: user.oid,
					attachments: [...tmpAttachments],
					conv_obj: null
				};
				return this.chatService.convDriveCreate(body).pipe(
					map(() => {
						this.router.navigate([AppRoutes.MYMAIA], { state: { newChatId: convId } })
					})
				)
			})
		)
	}, { dispatch: false });

	createNewChatQuickAction$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(udriveActions.createNewChatQuickAction),
			switchMap((action) => {
				let msg!: string;
				switch (action.action) {
					case QuickActionType.TRANSLATE:
						msg = this.translate.instant('UDRIVE.action_translate');
						break
					case QuickActionType.SUMMARIZE:
						msg = this.translate.instant('UDRIVE.action_summarize');
						break
					case QuickActionType.TRANSCRIBE:
						msg = this.translate.instant('UDRIVE.action_transcribe');
						break
					case QuickActionType.PARAPHRASE:
						msg = this.translate.instant('UDRIVE.action_paraphrase');
						break
					case QuickActionType.DESCRIBE:
						msg = this.translate.instant('UDRIVE.action_describe');
						break
				}
				let convId = uuid();
				// let body = {
				// 	id_file: action.idFile,
				// 	action: action.action as QuickActionType,
				// 	conversationId: convId,
				// 	isCreate: true,
				// 	msg: msg + ' ' + action.fileName
				// }

				let body = {
					id_file: action.idFile,
					action: action.action as QuickActionType,
					msg: action.lang ? `${msg} ${action.fileName} ${this.translate.instant('COMMON.to')} ${action.lang.name}` : `${msg} ${action.fileName}`,
					conversationId: convId,
					...(action.lang && { lang: action.lang.code }),
					isCreate: true
				};

				return this.udriveService.quickActions(body).pipe(
					map(() => {
						this.router.navigate([AppRoutes.MYMAIA], { state: { newChatId: convId } })
					})
				)
			})
		)
	}, { dispatch: false });

	constructor(
		private actions$: Actions,
		private store: Store,
		private udriveService: UdriveService,
		private chatService: ChatService,
		private router: Router,
		private translate: TranslateService
	) { }
}