import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordSectionComponent } from './password-section.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '../../loader/loader.module';

@NgModule({
    declarations: [PasswordSectionComponent],
    exports: [PasswordSectionComponent],
    imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, LoaderModule],
    providers: [],
})
export class PasswordSectionModule { }
