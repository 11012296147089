import { createReducer, on, Action } from '@ngrx/store';
import { PersonalSettingsState, initialAppState } from '../app.interface';
import * as actions from '../actions/personalSettings.action';
import { SettingModel, SettingNotification, NotificationType, SourceManagement, SettingsKeyName } from '@models/setting.model';
import { ContactsSettings, MaiaApplicationPreferences } from '@models/personalSettings.model';


export const reducer = createReducer(
    initialAppState.PersonalSettingsState,
    on(actions.SetMaiaApplicationPreferences, (state, payload): PersonalSettingsState => {
        return {
            ...state,
            maiaApplication: payload.value,
        };
    }),
    on(actions.SetSettings, (state, payload): PersonalSettingsState => {
        let timeZone = payload.value.find(
            (el: SettingModel) => el.key === SettingsKeyName.TIMEZONE
        );
        let maiaApplication: any = payload.value.find(
            (el: SettingModel) => el.key === SettingsKeyName.MAIA_APPLICATION
        );
        let sourceManagement: SourceManagement = { blackList: [], whiteList: [] };
        let notification: SettingNotification = {
            [NotificationType.browser_notifications]: true,
            [NotificationType.sound_browser_notifications]: true,
            [NotificationType.mail_notifications]: true,
            [NotificationType.calendar_events]: true,
        };
        let contactSettings: ContactsSettings = {
            sortBy: 'firstName'
        }
        if (!maiaApplication) {
            maiaApplication = {
                ...state.maiaApplication,
                background: '/assets/images/GRADIENT-04.jpg',
                animation: [{ id: 'enabled', name: 'Enabled' }],
                appearance: [{ id: 'light', name: 'Light' }],
                settId: undefined,
                mobileMic: false,
                muteMaia: false,
            };
        } else {
            let tmpSettings = JSON.parse(maiaApplication.value);
            maiaApplication = {
                ...state.maiaApplication,
                animation: tmpSettings.animation,
                appearance: tmpSettings.appearance,
                background: tmpSettings.background,
                settId: maiaApplication.id,
                mobileMic: tmpSettings.mobileMic,
                muteMaia: tmpSettings.muteMaia ? tmpSettings.muteMaia : false,
            };
            if (tmpSettings.sourceManagement) sourceManagement = tmpSettings.sourceManagement;
            if (tmpSettings.notification) notification = tmpSettings.notification;
            if (tmpSettings.contactSettings) contactSettings = tmpSettings.contactSettings;
        }
        return {
            ...state,
            googleConnection: {
                ...state.googleConnection,
                googleConnect: payload.value.some(
                    (el: SettingModel) => el.key === SettingsKeyName.GOOGLE_TOKEN
                ),
                timeZone: {
                    val: timeZone ? timeZone.value : '',
                    settId: timeZone ? timeZone.id : undefined,
                },
            },
            maiaApplication: maiaApplication as MaiaApplicationPreferences,
            sourceManagement,
            notification,
            contactSettings
        };
    }),
    on(actions.SetInformationUser, (state, payload): PersonalSettingsState => {
        return {
            ...state,
            informations: { ...payload.value },
        };
    }),
    on(actions.SetSourceManagement, (state, payload): PersonalSettingsState => {
        return {
            ...state,
            sourceManagement: payload.value,
        };
    }),


    on(actions.UpdateInformationUser, (state, payload): PersonalSettingsState => {
        return {
            ...state,
            informations: { ...payload.value },
        };
    }),

    on(actions.SetCurrPath, (state, payload): PersonalSettingsState => {
        return {
            ...state,
            maiaApplication: {
                ...state.maiaApplication,
                currPath: payload.currPath
            }
        };
    }),

    on(actions.SetGoogleToken, (state, payload): PersonalSettingsState => {
        return {
            ...state,
            googleConnection: { ...payload.value },
        };
    }),
    on(actions.UpdateNotification, (state, payload): PersonalSettingsState => {
        return {
            ...state,
            notification: { ...payload.value },
        };
    }),
    on(actions.SaveContactSettings, (state, payload): PersonalSettingsState => {
        return {
            ...state,
            contactSettings: {
                sortBy: payload.value.sortBy
            },

        }
    }
    ),
    on(actions.SaveContactSettingsSuccess, (state, payload): PersonalSettingsState => {
        return {
            ...state,
            contactSettings: payload.value,
        };
    }
    ),
    on(actions.UpdateTimezone, (state, payload): PersonalSettingsState => {
        return {
            ...state,
            googleConnection: {
                ...state.googleConnection,
                timeZone: {
                    ...state.googleConnection.timeZone,
                    val: payload.value
                }
            }
        };
    }
    ),
    on(actions.SetCalendars, (state, payload): PersonalSettingsState => {
        return {
            ...state,
            googleConnection: {
                ...state.googleConnection,
                googleCalendar: [...payload.value]
            }
        };
    }
    ),
    on(actions.UpdateEnabledCalendars, (state, payload): PersonalSettingsState => {
        return {
            ...state,
            googleConnection: {
                ...state.googleConnection,
                googleCalendar: [...payload.value]
            }
        };
    }
    ),
    on(actions.SetMuteMaia, (state, payload): PersonalSettingsState => {
        return {
            ...state,
        };
    }),
    on(actions.setShareUser, (state, payload): PersonalSettingsState => {
        if (payload.value.lang) {
            return {
                ...state,
                informations: {
                    ...state.informations,
                    language: payload.value.lang
                }
            };
        } else {
            return {
                ...state,
                informations: {
                    ...state.informations,
                    id: payload.value.userId
                }
            };
        }
    }),
    on(actions.setUknowUser, (state, payload): PersonalSettingsState => {
        return {
            ...state,
            informations: {
                ...state.informations,
                id: payload.value.guest_id,
                displayName: payload.value.name + ' ' + payload.value.surname,
                name: payload.value.name,
                surname: payload.value.surname,
                email: payload.value.email,
            }
        };
    }),
);

export function PersonalSettingsReducer(
    state: PersonalSettingsState | undefined,
    action: Action
): PersonalSettingsState {
    return reducer(state as PersonalSettingsState, action as Action);
}
