import { Component, Input, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
    selector: 'maia-last-step-section',
    templateUrl: './last-step-section.component.html',
    styleUrls: ['./last-step-section.component.scss'],
})
export class LastStepSectionComponent implements OnInit {
    @Input() personName!: string;

    constructor() { }

    ngOnInit(): void { }



}
